<template>
  <div class="page roadmap-map d-flex flex-column">
    <MapSidemenu ref="menu" closed force-arrow>
      <span v-if="roadmap">
        <FrontpageSection :title="getItemAttr(roadmap.country, 'name', $i18n.locale)">
          <template v-slot:above_title>
            <div class="top-items">
              <div class="breadcrumbs d-flex flex-row py-2">
                <font-awesome-icon class="home-icon" icon='home'/>
                <span class="bc-divider">></span>
                <span class="bc-item" @click="roadmap = null; roadmap2 = null; $refs.map.reset()" style="cursor: pointer">{{ $t("roadmaps_punchcard.overview") }}</span>
              </div>
            </div>
          </template>
          <!-- INFO: The author in mostly null -->
          <small>{{roadmap.country.created_by}}</small>
          <div v-if="show_compare">
            <div style="display:flex;">
              {{ $t('roadmaps_punchcard.compare') }}:
              <v-select class="pl-1" style="width:50%;" :getOptionLabel="opt => getItemAttr(opt.country, 'name', $i18n.locale)" :options="roadmaps" v-on:input="compare"></v-select>
            </div>
          </div>
          <RoadmapPunchcard :roadmap="roadmap" :roadmap2="roadmap2"/>
        </FrontpageSection>
      </span>
      <span v-else>
        <FrontpageSection title="UNFCCC-Roadmaps">
          <template v-slot:below_title>
            <Breadcrumbs></Breadcrumbs>
          </template>
          <DynText txt="roadmap_text"></DynText>
          <!-- data table -->
          <v-client-table :columns="columns" v-model="roadmaps" :options="options" role="table">
            <div slot="country" slot-scope="row">
              <a href="#" @click="onCountryClick(row.row.country.short_name, true)">{{ getCountryName(row.row.country) }}</a>
            </div>
          </v-client-table>
        </FrontpageSection>
      </span>
      <SubtleFooter map></SubtleFooter>
    </MapSidemenu>
    <div class="map-title">
      <h1>{{ $t("roadmaps_punchcard.title") }}</h1>
      <CountryTooltip v-if="showTooltip" :feature="tooltipFeature" class="mt-2"></CountryTooltip>
    </div>
    <CountryMap global ref="map" :lat="52" :lon="19" :highlightCountries="true" @country="onCountryClick" @hover="onCountryHover" @reset="onReset"></CountryMap>
  </div>
</template>

<script>
import CountryMap from "@/components/map/CountryMap";
import MapSidemenu from "@/components/map/MapSidemenu";
import FrontpageSection from "@/components/frontpage/Section";
import RoadmapPunchcard from "@/components/Roadmaps/RoadmapPunchcard";
import { fetchRoadmaps } from "@/api";
import CountryTooltip from "@/components/CountryTooltip";
import SubtleFooter from "@/components/SubtleFooter";
import DynText from "@/components/DynText";
import Breadcrumbs from "@/components/Breadcrumbs";
import { constants } from "@/constants/constants.js";
import { getItemAttr } from "@/tools";

export default {
  name: "RoadmapMap",

  components: {
    CountryMap,
    MapSidemenu,
    FrontpageSection,
    RoadmapPunchcard,
    CountryTooltip,
    SubtleFooter,
    DynText,
    Breadcrumbs,
  },
  data() {
    return {
      searchterm: "",
      roadmaps: [],
      filtered_roadmaps: [],
      tooltipFeature: {},
      showTooltip: false,
      roadmap: "",
      roadmap2: null,
      // data table
      columns: ["country"],
      options: {
        headings: {
          country: "",
        },
        pagination: {
          show: false,
          dropdown: false,
        },
        texts: {
          filterPlaceholder: "",
        },
        perPage: 100,
        editableColumns: [],
        sortable: ["country"],
        filterable: ["country"],
      },
    };
  },
  methods: {
    getItemAttr: getItemAttr,
    search() {
      if (this.searchterm === "") {
        this.filtered_roadmaps = this.roadmaps;
      } else {
        this.filtered_roadmaps = this.roadmaps.filter(e => e.country.name_en_GB === this.searchterm);
      }
    },
    onCountryClick(country, manual) {
      this.$refs.menu.resetScroll();
      // open the menu if it is closed
      if (!this.$refs.menu.showSidemenu) {
        this.$refs.menu.toggle();
        this.$refs.menu.forceArrow = true;
      }

      // filter for the correct country
      this.roadmap = this.roadmaps.filter(e => e.country.short_name === country)[0];
      if (manual) {
        this.$refs.map.highlightSelected(country);
      }
    },
    onCountryHover(feature) {
      this.tooltipFeature = feature;
      this.showTooltip = true;
    },

    onReset() {
      this.showTooltip = false;
    },
    getCountryName(country) {
      if (this.$i18n.locale.startsWith("de") && country.name_de !== null) {
        return country.name_de;
      } else {
        return country.name_en_GB;
      }
    },
    compare(roadmap2) {
      this.roadmap2 = roadmap2;
    },
    renderLegend() {
      this.$refs.map.clearLegend();
      let legend_array = [
        { type: "row", image: require("@/assets/icons/stars_zero.png"), text: this.$t("roadmaps_punchcard.legend.none") },
        { type: "row", image: require("@/assets/icons/stars_one.png"), text: this.$t("roadmaps_punchcard.legend.little") },
        { type: "row", image: require("@/assets/icons/stars_two.png"), text: this.$t("roadmaps_punchcard.legend.several") },
        { type: "row", image: require("@/assets/icons/stars_three.png"), text: this.$t("roadmaps_punchcard.legend.much") },
        { type: "row", image: require("@/assets/icons/none.png"), text: this.$t("roadmaps_punchcard.legend.no_information") },
        { type: "row", image: require("@/assets/icons/not_considered.png"), text: this.$t("roadmaps_punchcard.legend.not_considered") },
        { type: "row", image: require("@/assets/icons/considered.png"), text: this.$t("roadmaps_punchcard.legend.considered") },
      ];
      for (let i = 0; i < legend_array.length; i++) {
        this.$refs.map.addLegend([
          legend_array[i],
        ]);
      }
    },
  },
  computed: {
    show_compare() {
      return window.screen.width >= constants.max_width_before_mobile;
    },
  },
  created() {
    fetchRoadmaps(roadmaps => {
      this.roadmaps = roadmaps;
      this.filtered_roadmaps = roadmaps;
    });
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let title = "Legende";
    if (this.$i18n.locale === "en") {
      title = "Legend";
    }
    this.renderLegend();

    let that = this;
    fetchRoadmaps(roadmaps => {
      this.$refs.map.highlightCountriesWithData(roadmaps);
      this.onCountryClick(this.$route.params.id, true);
    });
  },
  updated() {
    if (this.$i18n.locale === "en") {
      this.options.headings.country = "Countries";
      this.options.texts.filterPlaceholder = "Search countries...";
    } else {
      this.options.texts.filterPlaceholder = "Länder durchsuchen...";
      this.options.headings.country = "Länder";
    }
  },
  watch: {
    "$i18n.locale": function(oldLoc, newLoc) {
      this.renderLegend();
    },
  },
};
</script>

<style lang="scss">
  .searchbar{
    width: 100%;
    border: 2px solid #005a9a;
  }
  .search-button{
    margin-left: 10px;
    margin-top: 5px;
  }

  .map-title {
    position: absolute;
    top: rem($map-overlay-top-position);
    right: rem(80);
    left: rem(60);
    z-index: 2000;

    h1 {
      color: #333;
      display: inline-block;
      margin-bottom: 0;
      background-color: white;
      opacity: 1;
      padding: rem(10) rem(15) rem(8);
      border-radius: rem(4);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

      --x-linear-scale-min-value: 16;
      --x-linear-scale-max-value: 30;
      @include linear-scale((font-size));
    }
  }

  //@media (max-width: rem($break-md - 1)) {
  //  .map-title h1 {
  //    --x-linear-scale-min-value: var(--x-default-min-font-size);
  //    --x-linear-scale-max-value: var(--x-default-max-font-size);
  //
  //    @include linear-scale((font-size));
  //  }
  //}

  .VueTables__limit {
    display: none;
  }

  .VueTables__search-field {
    label {
      display: none;
    }
  }

  .top-items{
    display: flex;
    flex-direction: row;
    gap: 18em;
  }

    .home-icon {
    color: $breadcrumb-color;
    font-weight: 300;
    font-size: 17px !important;
  }

  .bc-item {
    font-family: "Roboto", "sans-serif" !important;
    color: $breadcrumb-color;
    font-weight: 400 !important;
    font-size: 17px !important;
  }

  .bc-divider {
    margin: 0 rem(10) 0 rem(10);
    font-family: "Roboto", "sans-serif";
    font-weight: 400;
    color: $breadcrumb-color;
    font-size: 17px !important;
  }

  .breadcrumbs {
    a:hover .bc-item {
      color: $brand-1;
      font-weight: 700;
    }
  }
</style>
