<template>
  <div class="frontpage-section">
    <div class="container">
      <slot name="above_title"></slot>
    </div>
    <frontpage-section-header :title="title">
    </frontpage-section-header>

    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FrontpageSectionHeader from "@/components/frontpage/SectionHeader";

export default {
  name: "FrontpageSection",

  components: {
    FrontpageSectionHeader,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data: () => ({
  }),

  computed: {
  },
};
</script>

<style scoped lang="scss">

.frontpage-section {
  font-size: rem(20);
  margin-bottom: rem(140);

  --x-linear-scale-min-value: 16;
  --x-linear-scale-max-value: 22;
  @include linear-scale((font-size));

  > .container {
    color: $font-color-light;
    font-weight: 400;
    font-size: rem(22);

    --x-linear-scale-min-value: 16;
    --x-linear-scale-max-value: 22;
    @include linear-scale((font-size))
  }
}

.frontpage-section-header {
  padding-bottom: rem(3);
  margin-bottom: rem(20);
  border-bottom: rem(3) solid $line-primary;
}

</style>
