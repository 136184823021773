<template>
    <div id="map"></div>
</template>

<script>
import * as Leaflet from "leaflet";
import { EPSG4326 } from "leaflet/src/geo/crs/CRS.EPSG4326";
import cluster from "leaflet.markercluster";
import { getMeta } from "@/tools";

export default {
  name: "Map",
  props: {
    lat: Number,
    lon: Number,
    layerUrl: String,
  },
  data() {
    return {
      map: {},
      zoom: 6,
      maxZoom: 14,
      minZoom: 5,
      globalZoom: 2.5,
      geoJson: {},
      layers: [],
    };
  },
  methods: {
    clearLegend() {
      document.querySelectorAll(".legend").forEach(e => e.remove());
    },
    addLegend(legendData = []) {
      var legend = Leaflet.control({ position: "bottomleft" });

      // re assign this for use in leaflet function
      let vue = this;
      legend.onAdd = function(map) {
        let div = Leaflet.DomUtil.create("div", "legend");
        if (legendData.length > 1) {
          div.style.flexDirection = "column";
        }

        for (let item of legendData) {
          if (item.type === "row") {
            let row = Leaflet.DomUtil.create("div", "legend-row");

            if (item.image) {
              let img = Leaflet.DomUtil.create("img", "legend-icon");
              img.src = item.image;
              row.appendChild(img);
            } else if (item.color || item.border_color) {
              let color = Leaflet.DomUtil.create("div", "legend-color");
              let style = "";
              if (item.mark) {
                color.className += " legend-" + item.mark + " legend-alt";
                if (item.mark === "cross") {
                  style += "color:" + item.color + ";";
                } else if (item.mark === "circle") {
                  style += "background-color:" + item.color + ";";
                }
              } else {
                if (item.border_color) {
                  style += "border-style: solid; border-color:" + item.border_color + ";";
                  style += "background-color:" + item.color + ";";
                } else {
                  style += "border-style: solid; border-color:" + item.border_color + ";";
                }
              }
              color.style = style;
              row.appendChild(color);
            }
            if (item.text) {
              let text = Leaflet.DomUtil.create("div", "legend-text");
              text.innerHTML += item.text;
              row.appendChild(text);
            }

            div.appendChild(row);
          } else if (item.type === "sub-heading") {
            let header = Leaflet.DomUtil.create("span", "legend-sub-header");
            header.innerHTML = item.text;
            div.appendChild(header);
          } else if (item.type === "scalebar") {
            let scalebar = Leaflet.DomUtil.create("div", "legend-scalebar");
            for (let i = 0; i < item.colors.length; i++) {
              const legend_item = Leaflet.DomUtil.create("div", "legend-raster-color");
              const color = Leaflet.DomUtil.create("div", "legend-raster-color");
              const legend_style = "background-color:" + item.colors[i] + ";";
              color.style = legend_style;
              let color_label = Leaflet.DomUtil.create("div", "legend-label");
              color_label.innerHTML += item.labels[i];
              legend_item.appendChild(color);
              legend_item.appendChild(color_label);
              scalebar.appendChild(legend_item);
            }
            div.appendChild(scalebar);
          }
          if (item.scalebar_title) {
            let header = Leaflet.DomUtil.create("span", "legend-sub-header");
            header.innerHTML = item.scalebar_title;
            if (item.unit) {
              header.innerHTML += " [" + item.unit + "]";
            }
            div.appendChild(header);
          }
        }

        return div;
      };
      legend.addTo(this.map);
    },
    init(global = false) {
      // check if zoom control should be enabled
      let zoomControl = true;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        zoomControl = false;
      }
      // init Leaflet
      if (global) {
        this.map = Leaflet.map("map", { zoomControl: zoomControl, plugins: [cluster], zoomSnap: 0.1 });
      } else {
        this.map = Leaflet.map("map", { zoomControl: zoomControl, plugins: [cluster], zoomSnap: 0.1, maxZoom: this.maxZoom });
      }

      if (zoomControl) {
        this.map.zoomControl.setPosition("topleft");
      }

      Leaflet.control.scale({ imperial: false }).addTo(this.map);


      // center the map on a defined latitude and longtitude
      if (global) {
        this.zoom = this.globalZoom;
        this.minZoom = this.globalZoom;
      }
      this.map.setView({ lon: this.lon, lat: this.lat }, this.zoom);
    },
    loadBase() {
      // load the base map
      Leaflet.tileLayer("https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}", {
        minZoom: this.minZoom,
        attribution: "Esri, HERE, Garmin, © OpenStreetMap contributors, and the GIS user community",
      }).addTo(this.map);
      // set the maps bounds
      // [[x-top, y-left], [x-bottom, y-right]]
      this.map.setMaxBounds([[-90, -400], [90, 400]]);
    },
    renderLayers(layer_idx = 0, givenOpacity = 1) {
      // I know the following code is ugly, but it´s the only way to get it working. If you try to refactor it please increase the counter below.
      // Hours wasted here: 3
      // If you find a better implementation please remove these comments :D.

      // remove all layers
      this.map.eachLayer((layer) => {
        layer.remove();
      });

      // clear legend
      document.querySelectorAll(".legend").forEach(e => e.remove());

      // reload the base layer
      this.loadBase();
      let that = this;
      let legends = [];
      // get the user layers
      fetch(this.layerUrl, { credentials: "include" }).then((response) => {
        return response.json();
      }).then(async(method) => {
        // map index defines which layer should be shown
        for (let i = 0; i < method.contribution_maps[layer_idx].contribution_map_layers.length; i++) {
          let layer = method.contribution_maps[layer_idx].contribution_map_layers[i];

          // fetch legend for layer
          let legend = [];
          await fetch(
            getMeta("geoserver-url-" + process.env.NODE_ENV) + "/geoserver/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=layer_" + layer.id + "&format=application/json"
          ).then((response) => {
            return response.json();
          }).then((response) => {
            let rules = response.Legend[0].rules;
            for (let i = 0; i < rules.length; i++) {
              let rule = rules[i];
              // rule name
              let title = "";
              if (rule.title) {
                title = rule.title;
              }
              let entries = rule.symbolizers[0];
              // Raster legend
              if (entries.Raster && entries.Raster.colormap && entries.Raster.colormap.entries) {
                let colors = [];
                let labels = [];
                let opacities = [];
                for (let j = 0; j < entries.Raster.colormap.entries.length; j++) {
                  let entry = entries.Raster.colormap.entries[j];
                  colors.push(entry.color);
                  labels.push(entry.label);
                  opacities.push(entry.opacity);
                }
                legend.push({
                  type: "scalebar",
                  colors: colors,
                  labels: labels,
                  opacities: opacities,
                  scalebar_title: layer.scalebar_title,
                  unit: layer.unit,
                });
              } else if (entries.Polygon) { // Polygon legend
                legend.push({
                  type: "row",
                  color: entries.Polygon.fill,
                  border_color: entries.Polygon.stroke,
                  text: title,
                  scalebar_title: layer.scalebar_title,
                  unit: layer.unit,
                });
              } else if (entries.Line) { // Line legend
                legend.push({
                  type: "row",
                  border_color: entries.Line.stroke,
                  text: title,
                  scalebar_title: layer.scalebar_title,
                  unit: layer.unit,
                });
              } else if (entries.Point && entries.Point.graphics) { // Point legend
                legend.push({
                  type: "row",
                  color: entries.Point.graphics[0].fill,
                  text: title,
                  scalebar_title: layer.scalebar_title,
                  unit: layer.unit,
                  mark: entries.Point.graphics[0].mark,
                });
              }
            }

            if (legend.length > 0) {
              legends.push(legend);
            }
          });

          // add layers to map
          this.layers.push(Leaflet.tileLayer.wms(getMeta("geoserver-url-" + process.env.NODE_ENV) + "/geoserver/netto0_workspace/wms?", {
            layers: "layer_" + layer.id,
            transparent: true,
            format: "image/png",
            zIndex: 500,
            // disabled tiled -> does not work correctly with zoom-levels
            tiled: false,
            minZoom: this.minZoom,
            crs: EPSG4326,
            opacity: givenOpacity,
          }).addTo(this.map));
        }

        for (let i = legends.length - 1; i >= 0; i--) {
          that.addLegend(legends[i]);
        }

        let scalebar_title = method.contribution_maps[layer_idx].scalebar_title;
        if (scalebar_title && method.contribution_maps[layer_idx].unit) {
          scalebar_title += " [" + method.contribution_maps[layer_idx].unit + "]";
        }
        if (scalebar_title) {
          that.addLegend([{
            type: "sub-heading",
            text: scalebar_title,
          }]);
        }
      });
    },
    addLayer(url) {
      this.layerUrl = url;
      this.renderLayers();
    },
  },
  watch: {
    "$i18n.locale": function(new_locale, old_locale) {
      if (!this.$route.name === "roadmaps") {
        this.renderLayers();
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
