<template>
  <div class="panel">
    <div class="description">
      <RoadmapPunchcardLabel
        name_de="Welche Hauptsektoren werden für eine Treibhausgas-Reduktion in der Roadmap berücksichtigt?"
        name_en="Which main sectors?"
      />
    </div>
    <span v-for="sector in sectors" :key="sector.id">
      <RoadmapPunchcardRow
        :values="[roadmap.sector.includes(sector.id)]"
        :values2="roadmap2? [roadmap2.sector.includes(sector.id)]:null"
        :roadmap2="roadmap2"
        :type="2"
        :name_de="sector.name_de"
        :name_en="sector.name_en_GB"
        :link="getGlossaryLink(sector.glossary)"
      />
    </span>
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";
import { getGlossaryLink } from "@/tools";

export default {
  name: "RoadmapPunchcardSectors",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
    // sectors: Array
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
    sectors() {
      return this.$store.getters["getSectors"];
    },
  },
  methods: {
    getGlossaryLink: getGlossaryLink,
    async initialize() {
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
