<template>
      <div class="">
            <div>
              <span v-if="this.$i18n.locale === 'de'">
                <a :href="roadmap.source_link" target="_blank">{{ roadmap.name_de }}</a>
              </span>
              <span v-else>
                <a :href="roadmap.source_link" target="_blank">{{ roadmap.name_en_GB }}</a>
              </span>
            </div>
            <div>
              {{ $t("roadmaps_punchcard.inhabitants") }} <span v-if="roadmap.country.inhabitants_year">({{ $t("roadmaps_punchcard.status_at_end") }} {{ roadmap.country.inhabitants_year }})</span>: {{roadmap.country.inhabitants}}
            </div>
            <RoadmapPunchcardChart :roadmap1="roadmap" :roadmap2="roadmap2" />
            <RoadmapPunchcardChartLegend/>
<!--            <Chart :player1="roadmap" :player2="player2" />-->
            <div id="accordion-container">
              <x-accordion :title="$t('roadmaps_punchcard.summary.title')">
                <RoadmapPunchcardSummary
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                  :slim = true
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.social_participation.title')" closed>
                <RoadmapPunchcardSocialParticipation
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.behavioural_change.title')" closed>
                <RoadmapPunchcardBehaviouralChange
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.sectors.title')" closed>
                <RoadmapPunchcardSectors
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                  :sectors="sectors"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.budget_pricing.title')" closed>
                <RoadmapPunchcardBudgetPricing
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.international.title')" closed>
                <RoadmapPunchcardInternational
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.other_ghgs.title')" closed>
                <RoadmapPunchcardOtherGHGs
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                  :ghgs="ghgs"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.negative_emissions.title')" closed>
                <RoadmapPunchcardNegativeEmissions
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                  :negativeEmissions="negativeEmissions"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.further_options.title')" closed>
                <RoadmapPunchcardFurtherOptions
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                />
              </x-accordion>
              <x-accordion :title="$t('roadmaps_punchcard.statistic.title')" closed>
                <RoadmapPunchcardStatistic
                  :roadmap="roadmap"
                  :roadmap2="roadmap2"
                  :amount="amount"
                  :languages="languages"
                  :countries="countries"
                />
              </x-accordion>
<!--              <x-accordion :title="$t('roadmaps_punchcard.legend.title')" closed>-->
<!--                <RoadmapPunchcardLegend-->
<!--                />-->
<!--              </x-accordion>-->
              <div class="buffer"></div>
            </div>
<!--          </div>-->
<!--        </div>-->
      </div>
<!--    </template>-->
<!--  </transition>-->
</template>

<script>
// import PlayerInfo from './PlayerInfo'
// import CompareButton from './CompareButton'
// import DetailsSectionContainer from './DetailsSectionContainer'
// import RoadmapSelector from './RoadmapSelector'
// import PlayerSelector from './PlayerSelector'

// import RoadmapPunchcardAccordion from './RoadmapPunchcardAccordion'
import RoadmapPunchcardSummary from "./RoadmapPunchcardSummary";
import RoadmapPunchcardStatistic from "./RoadmapPunchcardStatistic";
import RoadmapPunchcardSectors from "./RoadmapPunchcardSectors";
import RoadmapPunchcardOtherGHGs from "./RoadmapPunchcardOtherGHGs";
import RoadmapPunchcardNegativeEmissions from "./RoadmapPunchcardNegativeEmissions";
import RoadmapPunchcardInternational from "./RoadmapPunchcardInternational";
import RoadmapPunchcardFurtherOptions from "./RoadmapPunchcardFurtherOptions";
import RoadmapPunchcardBudgetPricing from "./RoadmapPunchcardBudgetPricing";
import RoadmapPunchcardSocialParticipation from "./RoadmapPunchcardSocialParticipation";
import RoadmapPunchcardBehaviouralChange from "./RoadmapPunchcardBehaviouralChange";
import RoadmapPunchcardChart from "./RoadmapPunchcardChart";
// import RoadmapPunchcardLegend from "./RoadmapPunchcardLegend";
import RoadmapPunchcardChartLegend from "./RoadmapPunchcardChartLegend";
import { fetchGHGs, fetchSectors, fetchNegativeEmissons, fetchAmount, fetchLanguages, fetchCountries } from "../../api";
import XAccordion from "@/components/core/Accordion";

export default {
  name: "RoadmapPunchcard",
  components: {
    RoadmapPunchcardSummary,
    RoadmapPunchcardStatistic,
    RoadmapPunchcardSectors,
    RoadmapPunchcardOtherGHGs,
    RoadmapPunchcardNegativeEmissions,
    RoadmapPunchcardInternational,
    RoadmapPunchcardFurtherOptions,
    RoadmapPunchcardBudgetPricing,
    RoadmapPunchcardSocialParticipation,
    RoadmapPunchcardBehaviouralChange,
    RoadmapPunchcardChart,
    RoadmapPunchcardChartLegend,
    // RoadmapPunchcardLegend,
    XAccordion,
    // RoadmapPunchcardAccordion
    // PlayerInfo, CompareButton, Legend, Chart, DetailsSectionContainer, RoadmapSelector//, PlayerSelector
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
    // roadmap () {
    //   return this.$store.getters.getRoadmap
    // }
  },
  methods: {
    async initialize() {
      fetchSectors(sectors => {
        this.sectors = sectors;
        this.$store.dispatch("setSectors", sectors);
      });
      fetchGHGs(ghgs => {
        this.ghgs = ghgs;
        this.$store.dispatch("setGHGs", ghgs);
      });
      fetchNegativeEmissons(negativeEmissions => {
        this.negativeEmissions = negativeEmissions;
        this.$store.dispatch("setNegativeEmissions", negativeEmissions);
      });
      fetchAmount(amount => {
        this.amount = amount;
        // this.$store.dispatch('setAmount', amount)
      });
      fetchLanguages(languages => {
        this.languages = languages;
        // this.$store.dispatch('setLanguages', languages)
      });
      fetchCountries(countries => {
        this.countries = countries;
        // this.$store.dispatch('setCountries', countries)
      });
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      let panel = event.target.parentNode.nextSibling;

      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
    sectors: null,
    ghgs: null,
    negativeEmissions: null,
    languages: null,
    countries: null,
    amount: null,
  }),
  created() {
    this.initialize();
    // this.$store.dispatch('setRm', this.roadmap)
    // this.$store.dispatch('setRoadmap', this.roadmap)
  },
};
</script>

<style scoped lang="scss">
</style>
