<template>
  <div class="element d-flex flex-column">
    <h4>{{ title }}</h4>

    <div class="d-flex">
      <div class="element-short-description" v-html="shortDescription"></div>

      <div class="d-flex align-items-end">
        <a class="element-link" role="link" @click="goToRoute" :aria-label="title + ' Details'" tabindex="0"><span>Details</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "FrontpageElement",

  props: {
    title: {
      type: String,
      required: true,
    },
    shortDescription: {
      type: String,
      required: false,
    },
    detailRoute: {
      type: String,
      required: true,
    },
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
    goToRoute() {
      router.push({ name: this.detailRoute });
    },

  },
};
</script>

<style scoped lang="scss">

.element {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-color: $frontpage-element-bg-color;
  border-radius: $main-border-radius;
  padding: rem(10);

  h4 {
    color: #fff;
    margin: 0 0 rem(12);
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;

    --x-linear-scale-min-value: 24;
    --x-linear-scale-max-value: 38;
    @include linear-scale((font-size));
  }

  > div {
    flex: 1;
  }
}

.element-short-description {
  line-height: 1.2;
  margin-top: auto;

  --x-linear-scale-min-value: 18;
  --x-linear-scale-max-value: 28;
  @include linear-scale((font-size));
}

.round-button:before {
  content: '';
  display: inline-block;;
  vertical-align: middle;
  padding-top: 100%;
}

span {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
}

.element-link {
  min-width: rem(90);
  max-width: rem(120);
  display: inline-block;
  cursor: pointer;
  color: $frontpage-element-bg-color;
  text-transform: uppercase;
  background-color: #fff;
  text-align: center;
  border-radius: 100%;

  &,
  &:hover {
    text-decoration: none;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
  }

  span {
    max-width: 90%;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;

    --x-linear-scale-min-value: 18;
    --x-linear-scale-max-value: 22;
    @include linear-scale((font-size));
  }
}

</style>
