<template>
  <div class="panel">
    <!--SEKTOREN-->
    <RoadmapPunchcardRow
      field_name="sector"
      :values="sectors"
      :type="1"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Hauptsektoren"
      name_en="Main sectors"
      :slim = "slim"
    />
    <!--GHGs-->
    <RoadmapPunchcardRow
      field_name="other_ghgs"
      :values="ghgs"
      :type="1"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Treibhausgase ausser CO<sub>2</sub>"
      name_en="Other GHGs"
      :slim = "slim"
    />
    <!--NEGATIVE EMISSIONS-->
    <RoadmapPunchcardRow
      field_name="negative_emission"
      :values="negativeEmissions"
      :type="1"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Negative Emissionen"
      name_en="Negative emissions"
      :slim = "slim"
    />
    <!--INTERNATIONAL-->
    <RoadmapPunchcardRow
      :values="[roadmap.international_offsets_included , roadmap.trans_border_services_included, roadmap.aviation_and_shipping_included]"
      :type="2"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Internationales"
      name_en="International"
      :slim = "slim"
    />
    <!--FURTHER OPTIONS-->
    <RoadmapPunchcardRow
      :values="[roadmap.nuclear_option_included, roadmap.adaptation_strategies, roadmap.climate_action_measures]"
      :type="2"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Weitere Optionen"
      name_en="Further options"
      :slim = "slim"
    />
    <!--BUDGET AND PRICING-->
    <RoadmapPunchcardRow
      :values="[roadmap.carbon_pricing, roadmap.ghg_budget_defined]"
      :type="2"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Treibhausgas-Budget und CO<sub>2</sub>-Bepreisung"
      name_en="GHG budget and carbon pricing"
      :slim = "slim"
    />
    <!--SOCIAL PARTICIPATION-->
    <RoadmapPunchcardRow
      :values="[roadmap.actors_involvement_in_development, roadmap.actors_involvement_in_implementation]"
      :type="3"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Akteursbeteiligung"
      name_en="Actor participation"
      :slim = "slim"
    />
    <!--BEHAVIOUR CHANGE-->
    <RoadmapPunchcardRow
      :values="[roadmap.behavioural_change]"
      :type="5"
      :roadmap="roadmap"
      :roadmap2="roadmap2"
      name_de="Verhaltensänderung"
      name_en="Behavioural change"
      :slim = "slim"
    />
    <!--PAGES-->
    <RoadmapPunchcardRow
      :values="[String(roadmap.roadmap_length)]"
      :values2="roadmap2? [String(roadmap2.roadmap_length)] : null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Seitenumfang"
      name_en="Roadmap length"
      :label_tooltip="$t('roadmaps_punchcard.summary.tooltip.roadmap_length')"
      :slim = "slim"
    />
    <!--YEAR GETTING EFFECTIVE-->
    <RoadmapPunchcardRow
      :values="[String(roadmap.year_of_getting_effective)]"
      :values2="roadmap2? [String(roadmap2.year_of_getting_effective)] : null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Jahr des Inkrafttretens"
      name_en="Year of getting effective"
      :label_tooltip="$t('roadmaps_punchcard.summary.tooltip.year_of_getting_effective')"
      :slim = "slim"
    />
    <!--TARGET NETTO NULL YEAR-->
    <RoadmapPunchcardRow
      :values="[String(roadmap.net_zero_year)]"
      :values2="roadmap2? [String(roadmap2.net_zero_year)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Zieljahr Netto-Null"
      name_en="Target net zero year"
      :label_tooltip="$t('roadmaps_punchcard.summary.tooltip.net_zero_year')"
      :slim = "slim"
    />
    <!--GHG REDUCTION BASE YEAR-->
    <RoadmapPunchcardRow
      :values="[String(roadmap.ghg_reduction_base_year)]"
      :values2="roadmap2? [String(roadmap2.ghg_reduction_base_year)] : null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Basisjahr für Treibhausgas-Reduktion"
      name_en="GHG reduction base year"
      :label_tooltip="$t('roadmaps_punchcard.summary.tooltip.ghg_reduction_base_year')"
      :slim = "slim"
    />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";

export default {
  name: "RoadmapPunchcardSummary",
  components: {
    RoadmapPunchcardRow,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
    slim: Boolean,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
    sectors() {
      return this.$store.getters["getSectors"];
    },
    ghgs() {
      return this.$store.getters["getGHGs"];
    },
    negativeEmissions() {
      return this.$store.getters["getNegativeEmissions"];
    },
  },
  methods: {
    async initialize() {
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
