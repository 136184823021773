import { render, staticRenderFns } from "./RoadmapPunchcardChart.vue?vue&type=template&id=24b3f0b7&scoped=true&"
import script from "./RoadmapPunchcardChart.vue?vue&type=script&lang=js&"
export * from "./RoadmapPunchcardChart.vue?vue&type=script&lang=js&"
import style0 from "./RoadmapPunchcardChart.vue?vue&type=style&index=0&id=24b3f0b7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b3f0b7",
  null
  
)

export default component.exports