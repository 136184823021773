<template>
  <div class="banner-slider">
    <vueper-slides
      class="no-shadow"
      bullets-outside
      :dragging-distance="70"
      :bullets="true"
      :fade="true"
      autoplay
      duration="10000"
      transitionSpeed="500"
      fixedHeight="500px"
      slide-image-inside
    >
    <vueper-slide class="banner-slider-slide" v-for="slide in slides" :key="slide.id" :image="slide.image" :content="slide.text"/>
      <template v-slot:bullet="{ active, slideIndex, index }">
        <i class="icon" :class="{'active_circle' : active , 'inactive_circle': !active}"></i>
      </template>
    </vueper-slides>

    <div class="container">
      <breadcrumbs id="breadcrumbs"></breadcrumbs>
    </div>
  </div>
</template>

<script>
import { VueperSlide, VueperSlides } from "vueperslides";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "FrontpageBannerSlider",

  components: {
    VueperSlides,
    VueperSlide,
    Breadcrumbs,
  },

  props: {
    slides: {
      type: Array,
      required: true,
    },
  },

  methods: {
  },

  data: () => ({
    partners: null,
  }),

  created() {
  },
};
</script>

<style scoped lang="scss">
.vueperslide__content {
  width: 100% !important;
}

.banner-slider {
  margin-bottom: rem(90);

  .vueperslides--fixed-height.vueperslides--bullets-outside {
    margin-bottom: 0;
  }
}

@media (max-width: rem($break-md)) {
  .banner-slider {
    margin-bottom: rem(30);
  }
}

.active_circle,
.inactive_circle {
  height: rem(15);
  width: rem(15);
  display: inline-block;
  margin: 0 rem(2);
  border-radius: 50%;
  border: rem(2) solid;
}

.active_circle {
  border-color: #707070;
  background-color: #707070;
}

.inactive_circle {
  border-color: #ffffff;
  background-color: #aaaaaa;
}

</style>

<style lang="scss">

.banner-slider-slide {
  text-align: left;
  font-weight: 300;

  .container > span {
    display: inline-block;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    background-color: rgba(0, 86, 147, 0.78);
    padding: rem(5) rem(14);

    --x-linear-scale-min-value: 26;
    --x-linear-scale-max-value: 44;
    @include linear-scale((font-size));

    &:not(:last-child) {
      margin-bottom: rem(10);
    }
  }
}

</style>
