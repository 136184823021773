import { render, staticRenderFns } from "./RoadmapPunchcardAmount.vue?vue&type=template&id=4679029a&scoped=true&"
import script from "./RoadmapPunchcardAmount.vue?vue&type=script&lang=js&"
export * from "./RoadmapPunchcardAmount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4679029a",
  null
  
)

export default component.exports