<template>
  <header class="d-flex" :class="{ 'internal-mode':internalMode }">
    <div>
      <router-link :to="{ name: 'home'}" :tabindex="0" :title="$t('title') + 'home'">
        <img v-if="$i18n.locale.startsWith('de')" class="logo" src="/logo.png" :alt="$t('title')" />
        <img v-else class="logo" src="/logo_en.png" :alt="$t('title')" />
      </router-link>
    </div>
    <div class="search-wrapper text-right" v-bind:class="{ open: searchOpen }">
      <input ref="searchInput" type="text" class="search-bar px-2" :placeholder="$t('search')" id="search" v-model="search" @input="doSearch">
      <div class="mobile-search-close align-items-center pr-1 pl-2">
        <i class="icon-cross" @click="toggleSearchOpen"></i>
      </div>

      <div class="search-results" v-show="searchResults.length > 0 && searchOpen" id="results">
        <ul>
          <li v-for="res in searchResults" :key="res">
            <a :href="res.path" v-html="res.title" :tabindex="0"></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-right d-flex align-items-center justify-content-end">
      <div class="search-icon" @click="toggleSearchOpen" :aria-label="$t('search')" tabindex="0" role="button">
        <i class="icon-search" v-if="!searchOpen"></i>
        <i class="icon-cross desktop" v-else></i>
      </div>

      <div class="localeSelector" :title="langTooltip">
        <span @click="setLocale('de')" v-if="$i18n.locale.includes('en')" tabindex="0" :aria-label="langTooltip">DE</span>
        <span @click="setLocale('en')" v-else tabindex="0" :aria-label="langTooltip">EN</span>
      </div>

      <a class="header-menu" tabindex="0" :aria-label="$t('menu')">
        <font-awesome-icon icon="bars" size="lg" color="#707070" @click="toggleMenu" class="cursor-pointer" />
        <Menu v-if="menuVisible" v-click-outside="toggleMenu" v-on:toggle-menu="toggleMenu" />
      </a>
    </div>
  </header>
</template>

<script>
import { colors } from "@/constants/colors";
import Menu from "@/components/Menu";
import { isLoggedIn } from "@/tools";

export default {
  name: "Header",

  components: {
    Menu,
  },

  data: () => ({
    menuActive: false,
    menuVisible: false,
    internalMode: false,
    searchOpen: false,
    search: "",
    searchResults: [],
  }),

  computed: {
    menuActiveColor() {
      return this.menuActive ? colors.lineOpponent : "white";
    },
    langTooltip() {
      if (this.$i18n.locale.startsWith("de")) {
        return "Change language to english";
      } else {
        return "Sprache auf Deutsch ändern";
      }
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
      window.localStorage.setItem("x-net", locale);
    },
    toggleMenu(val) {
      if (val) {
        this.menuActive = !this.menuActive;
        this.menuVisible = !this.menuVisible;
      } else {
        this.menuActive = !this.menuActive;
        this.menuVisible = !this.menuVisible;
      }
    },
    toggleSearchBar() {
      let searchBar = document.getElementById("search");
      let searchBox = document.getElementById("results");
      searchBar.toggleAttribute("hidden");
      searchBox.toggleAttribute("hidden");
    },

    toggleSearchOpen() {
      this.searchOpen = !this.searchOpen;

      if (this.searchOpen) {
        this.$refs.searchInput.focus();
      }
    },

    doSearch() {
      let url = new URL(this.$apiURL + "/search/");
      url.searchParams.append("q", this.search);
      url.searchParams.append("lang", this.$i18n.locale);
      if (this.search.length >= 3) {
        fetch(url).then(resp => {
          return resp.json();
        }).then(data => {
          this.searchResults = data;
        });
      } else {
        this.searchResults = [];
      }
    },

    getSearchIconAriaLabel() {
      if (this.searchOpen) {
        return this.$t("close");
      } else {
        return this.$t("search");
      }
    },
  },

  async created() {
    this.loggedIn = await isLoggedIn();
  },
  watch: {
    $route(to, from) {
      // this.search = "";
      this.searchOpen = false;
      // this.searchResults = [];
    },
  },
};
</script>

<style scoped lang="scss">
  header {
    height: rem($header-height);
    position: sticky;
    top: 0;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    //box-shadow: 0px 15px 10px -1px rgba(112,112,112,0.46);
    box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
    padding: 0 rem(15) 0 rem(10);
    z-index: 9999;
  }

  .header-right {
    flex: 1;
  }

  .logo {
    height: 3.3em;
    cursor: pointer;
    padding-left: 0.5em;
    padding-top: 0.2em;
  }

  @media (max-width: rem($break-md - 1)) {
    .logo {
      height: auto;
      max-width: rem(160);
    }
  }

  .right {
    display: flex;
    flex-flow: row nowrap;
    padding-right: 1em;
    color: #707070;
  }

  .localeSelector {
    margin-top: 0.1em;
    z-index: 20000;
    color: #707070;
    padding: 0em;
    font-size: 0.8em;
    cursor: pointer;
    margin-right: 2em;

    & span:hover {
      font-weight: bold;
    }
  }

  .icon {
    margin-right: 10px;
    margin-top: 0.1em;
  }

  .search-bar {
    width: 100%;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid lightgrey;
    border-radius: var(--x-border-radius);

    &:focus {
      border-color: $primary;
    }
  }

  .header-menu {
    position: relative;
  }

  .internal-message{
    margin-left: 1em;
    font-weight: bolder;
    color: red;
  }

  .search-wrapper {
    height: 100%;
    width: 0;
    display: none;
    padding: rem(7) 0 rem(7) rem(25);

    &.open {
      width: 100%;
      display: block;
    }
  }

  .search-bar-wrapper {
    height: 100%;
    padding: rem(4) 0;
  }

  .search-icon > i,
  .mobile-search-close {
    color: #707070;
  }

  .search-icon > i {
    color: #707070;
    font-size: rem(18);
    padding: 0 rem(15) 0 rem(10);
    margin-right: rem(5);

    &.icon-cross {
      font-size: rem(15);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .mobile-search-close {
    display: none;
  }

  .search-results {
    display: flex;
    //width: 100%;
    max-height: 50em;
    background-color: #fff;
    z-index: 999999;
    border-radius: rem(5);
    margin-top: rem(15);
    // overflow: hidden;
    overflow-y: auto;
    border: rem(1) solid #dbdbdb;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);

    > ul, > ul > li {
      list-style: none;
    }

    > ul {
      padding: 0;
      margin: rem(5) 0;
      width: 100%;
    }

    > ul > li a {
      display: block;
      padding: rem(10) rem(15);
      text-align: left;

      &:hover {
        background-color: #f4f4f4;
      }
    }

    > ul > li:not(:last-child) a {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  @media (max-width: rem($break-sm)){
    .search-results{
      max-width: rem(160);
    }

    .search-wrapper{
      margin-left: rem(-20);
    }
  }
  //@media (max-width: rem($break-sm)) {
  //  .search-wrapper {
  //    height: rem($header-height);
  //    display: flex;
  //    flex-flow: row;
  //    padding-right: rem(7);
  //    padding-left: rem(7);
  //    position: fixed;
  //    top: 0;
  //    right: 0;
  //    left: 0;
  //    background-color: #fff;
  //    z-index: 99999;
  //  }
  //
  //  .mobile-search-close {
  //    display: flex;
  //  }
  //
  //  .search-results {
  //    width: auto;
  //    position: fixed !important;
  //    top: rem($header-height);
  //    right: 0;
  //    bottom: 0;
  //    left: 0;
  //    padding: rem(10);
  //    margin-top: rem(0);
  //    border-radius: 0;
  //    overflow-y: auto;
  //
  //    > ul > li a {
  //      padding-right: 0;
  //      padding-left: 0;
  //    }
  //  }
  //}
</style>
