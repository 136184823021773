<template>
  <div class="page about-page">
    <div class="about">
      <frontpage-section :title="$t('menu.about_atlas')">
        <Breadcrumbs class="breadcrumbs"></Breadcrumbs>
        <div>
          <DynText txt="about_atlas" class="txt"></DynText>
        </div>

        <hr style="margin-top: 3em">
        <h2>Matomo Websiteanalyse</h2>
        <iframe
        style="border: 0; height: 200px; width: 600px;"
        :src="matomoURL"
        ></iframe>
      </frontpage-section>
    </div>
    <subtle-footer class="footer"></subtle-footer>
  </div>
</template>

<script>
import FrontpageSection from "@/components/frontpage/Section";
import SubtleFooter from "@/components/SubtleFooter";
import DynText from "@/components/DynText";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "AboutAtlas",
  components: {
    FrontpageSection,
    SubtleFooter,
    DynText,
    Breadcrumbs,
  },
  computed: {
    matomoURL() {
      return `https://analytics.x-net.at/index.php?module=CoreAdminHome&action=optOut&language=${this.$i18n.locale}&backgroundColor=ffffff&fontColor=606060&fontSize=16px&fontFamily=Roboto%20sans-serif`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .about-page{
    margin-top: 3em;
  }

  .about{
    min-height: 100%;
  }

  .breadcrumbs{
    margin-bottom: 1em;
  }
</style>
