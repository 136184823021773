<template>
  <div class="frontpage-section-header">
    <div class="container">
      <h1 v-html="title"></h1>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontpageSectionHeader",

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },
};
</script>

<style scoped lang="scss">

.frontpage-section-header {
  padding-bottom: rem(3);
  margin-bottom: rem(20);
  border-bottom: rem(3) solid $line-primary;

  h1 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    overflow-wrap: anywhere;
    display: inline-block;
    color: #606060;

    --x-linear-scale-min-value: 21;
    --x-linear-scale-max-value: 30;
    @include linear-scale((font-size));
  }
}

</style>
