<template>
  <span class="roadmap_punchcard_amount">
    <template v-if="value === 4">
      <img src="../../assets/icons/stars_zero.png" class="icon_stars">
    </template>
    <template v-else-if="value === 3">
      <img src="../../assets/icons/stars_one.png" class="icon_stars">
    </template>
    <template v-else-if="value === 2">
      <img src="../../assets/icons/stars_two.png" class="icon_stars">
    </template>
    <template v-else-if="value === 1">
      <img src="../../assets/icons/stars_three.png" class="icon_stars">
    </template>
  </span>
</template>

<script>
export default {
  name: "RoadmapPunchcardAmount",
  props: {
    value: Number,
  },
};
</script>

<style scoped lang="scss">
</style>
