<template>
  <div id="id_canvas_div" style="display:flex;width:100%;">
    <canvas id="chart" ref="chart" style="flex:1;" :width="width"></canvas>
    <canvas v-if="this.roadmap2" id="chart2" ref="chart2" style="flex:1;" :width="width"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
// import 'chartjs-plugin-annotation'
import { colors } from "@/constants/colors.js";
import { constants } from "@/constants/constants.js";
// import { getName } from "../tools";
// import pattern from "patternomaly";

let chart;
let chart2;
let mobile = false;
let originalDraw;

// const hasValue = field => field != null && typeof field !== 'undefined'

export default {
  name: "Chart",
  props: {
    roadmap1: Object,
    roadmap2: Object,
  },
  mounted() {
    // this.roadmap1.isPlayer1 = true
    // if (this.player2) this.player2.isPlayer1 = false
    this.draw();
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
    width() {
      const padding = 30;
      if (this.roadmap2) {
        return document.querySelector(".container").clientWidth / 2 - padding;
      } else {
        return document.querySelector(".container").clientWidth - padding;
      }
    },
  },
  watch: {
    roadmap1: function(to) {
      if (!to) return;
      if (chart) {
        chart.destroy();
      }
      this.draw();
    },
    roadmap2: function(to) {
      if (chart) {
        chart.destroy();
      }
      if (chart2) {
        chart2.destroy();
      }
      let that = this;
      setTimeout(function() {
        that.draw();
      }, 500);
    },
    "$i18n.locale": function(newLocale, oldLocale) {
      this.draw();
    },
    // player2: function (to) {
    //   if (chart) chart.destroy()
    //   this.draw()
    // },
    // 'roadmap1.selectedEntry': function (to) {
    //   if (chart) chart.destroy()
    //   this.draw()
    // }
    // 'player2.selectedEntry': function (to) {
    //   if (chart) chart.destroy()
    //   this.draw()
    // }
  },
  created() {
    this.extend_chart_draw();
  },
  destroyed() {
    // restore originaldraw
    if (originalDraw) {
      Chart.controllers.bar.prototype.draw = originalDraw;
    }
  },
  methods: {
    extend_chart_draw() {
      const that = this;
      originalDraw = Chart.controllers.bar.prototype.draw;

      Chart.helpers.extend(Chart.controllers.bar.prototype, {
      // var custom = Chart.controllers.bar.extend({
        draw: function() {
          originalDraw.call(this, arguments);
          var chart = this.chart;
          var ctx = chart.chart.ctx;
          var emissionBar = chart.getDatasetMeta(0);
          var emissionsMin = chart.data.datasets[1];
          var emissionsMax = chart.data.datasets[2];
          var reductionsMin = chart.data.datasets[3];
          var reductionsMax = chart.data.datasets[4];

          ctx.save();

          const minValue = emissionsMin.data[0];
          const min = chart.scales["bar"].getPixelForValue(minValue);

          ctx.textAlign = "center";
          const default_font = "15px Arial";

          ctx.font = default_font;

          // first bar content
          ctx.fillStyle = "rgba(255,255,255,1.0)";
          if (!that.roadmap2 && !mobile) {
            ctx.fillText("~" + Math.round(emissionsMin.data[0]).toString().replace(".", ","), emissionBar.data[0]._view.x, (chart.scales["bar"].getPixelForValue(0) - min) / 2);
          }

          for (let i = 1; i < emissionBar.data.length; i++) {
            ctx.lineWidth = 1;
            let y1 = emissionBar.data[i]._view.y + 1; // bar-data
            let y2 = chart.scales["bar"].getPixelForValue(emissionsMin.data[i]) + 1; // emissionsMin
            let y1Val = emissionsMax.data[i];
            let y2Val = emissionsMin.data[i];

            // draw triangle if min/max values
            const halfW = emissionBar.data[i]._view.width / 2;
            if (y2Val < minValue) {
              if (i === 1) {
                ctx.fillStyle = colors.gray;
                ctx.strokeStyle = colors.transparent;
              } else {
                ctx.fillStyle = colors.gray_60;
                ctx.strokeStyle = colors.transparent;
              }
              const stroke_width = 0.75;
              ctx.beginPath();
              ctx.moveTo(emissionBar.data[i]._view.x + halfW, y1 - stroke_width);
              ctx.lineTo(emissionBar.data[i]._view.x - halfW, y1 - stroke_width);
              ctx.lineTo(emissionBar.data[i]._view.x - halfW, y2 - stroke_width);
              ctx.fill();
            }

            ctx.beginPath();
            let y1Draw = y1;
            let y2Draw = y2;
            let minDraw = 0;
            // green rectangle if value < min
            if (y2Val <= minValue) {
              minDraw = min;
              if (i === 1) {
                ctx.fillStyle = colors.turquoise;
                ctx.strokeStyle = colors.turquoise;
              } else {
                ctx.fillStyle = colors.turquoise_60;
                ctx.strokeStyle = colors.turquoise_60;
              }
              ctx.lineWidth = 1;
            }
            if (y2Val > minValue || y1Val > minValue) { // red rectangle if y1Val or y2Val > min
              minDraw = min - 5;
              y1Draw = 3;
              y2Draw = 3;
              ctx.lineWidth = 8;
              ctx.strokeStyle = colors.red_60;
              ctx.fillStyle = colors.red_60;

              if (!that.roadmap2 && !mobile) {
                ctx.moveTo(emissionBar.data[i]._view.x - halfW, y1Draw);
                ctx.lineTo(emissionBar.data[i]._view.x + halfW, y1Draw);
                ctx.lineTo(emissionBar.data[i]._view.x, minDraw);
                ctx.lineTo(emissionBar.data[i]._view.x - halfW, y1Draw);
                ctx.fill();
              }
            }
            y1 -= 5;
            y2 -= 5;
            y1Draw -= 5;
            y2Draw -= 5;

            // draw triangle/rectangle
            if (y1Draw || y2Draw) {
              if (y2Val <= minValue || y1Val <= minValue) {
                y2Draw = y2;
                y1Draw = y1;
                ctx.lineWidth = 1;
                // for special case -> one val is above, one val is below min
                if ((y2Val > minValue) || (y1Val > minValue)) {
                  if (i === 1) {
                    ctx.fillStyle = colors.turquoise;
                    ctx.strokeStyle = colors.turquoise;
                  } else {
                    ctx.fillStyle = colors.turquoise_60;
                    ctx.strokeStyle = colors.turquoise_60;
                  }

                  if (y2Val > minValue) {
                    y2Draw = y1Draw;
                  }
                  if (y2Val > minValue) {
                    y1Draw = y2Draw;
                  }
                  minDraw += 5;
                }
                ctx.beginPath();
                ctx.moveTo(emissionBar.data[i]._view.x - halfW, minDraw);
                ctx.lineTo(emissionBar.data[i]._view.x - halfW, y2Draw);
                ctx.lineTo(emissionBar.data[i]._view.x + halfW, y1Draw);
                ctx.lineTo(emissionBar.data[i]._view.x + halfW, minDraw);
                ctx.lineTo(emissionBar.data[i]._view.x - halfW, minDraw);
                ctx.lineTo(emissionBar.data[i]._view.x - halfW, y2Draw);
                ctx.stroke();
                ctx.fill();
              }
            }
            ctx.strokeStyle = colors.transparent;

            // draw text in rectangles
            ctx.fillStyle = colors.white;
            let x = emissionBar.data[i]._view.x;
            let y = y2 - 10;
            if (y2Val > minValue) {
              y = y1 - 15;
            }
            if (y < min + 25) {
              y = chart.scales["bar"].getPixelForValue(emissionsMax.data[i]) + 25;
            }
            let minVal = reductionsMin.data[i];
            let maxVal = reductionsMax.data[i];
            // text above 0-line
            if (y2Val > minValue) {
              ctx.font = "12px Arial";
              ctx.fillStyle = colors.gray;
              ctx.textAlign = "center";
              const lineHeight = 20;
              if (!that.roadmap2 && !mobile) {
                let val = maxVal;
                if (minVal !== maxVal) {
                  if (minVal > maxVal) {
                    val = minVal;
                  }
                }
                ctx.fillText(that.$t("roadmaps_punchcard.chart.emissions").toString().replace(".", ","), x, lineHeight);
                let text = "";
                if (minVal !== val) {
                  text = that.$t("roadmaps_punchcard.chart.increase") + ": +" + Math.abs(val).toString().replace(".", ",") + "% - +" + Math.abs(minVal).toString().replace(".", ",") + "%";
                } else {
                  text = that.$t("roadmaps_punchcard.chart.increase") + ": +" + Math.abs(val).toString().replace(".", ",") + "%";
                }
                ctx.fillText(text, x, lineHeight * 2);
                ctx.fillText("~" + emissionsMin.data[i].toString().replace(".", ",") + " Mt CO2 -eq", x, lineHeight * 3);
              }
              ctx.textAlign = "center";
            }
            ctx.fillStyle = colors.white;
            if (y2Val <= minValue || y1Val <= minValue) {
              ctx.font = default_font;
              // min+max text
              if (y1 !== y2 && (y1Val <= minValue && y2Val <= minValue)) {
                y += 5;
                if (maxVal) {
                  if (!that.roadmap2 && !mobile) {
                    ctx.fillText(maxVal.toString().replace(".", ",") + "%", x + halfW / 2, y);
                  }
                }
                if (minVal) {
                  if (!that.roadmap2 && !mobile) {
                    ctx.fillText(minVal.toString().replace(".", ",") + "%", x - halfW / 2, y);
                  }
                }
              } else { // simple text
                if (y2Val <= minValue) {
                  maxVal = minVal;
                }
                if (maxVal) {
                  if (!that.roadmap2 && !mobile) {
                    ctx.fillText(maxVal.toString().replace(".", ",") + "%", x, y);
                  }
                }
              }
            }
          }
          ctx.restore();
        },
      });
    },
    draw() {
      if (window.screen.width < constants.max_width_before_mobile) {
        mobile = true;
      }

      const context = this.$refs.chart.getContext("2d");
      const maxBar = this.prepareBarData(this.roadmap1)[0];
      chart = new Chart(context, {
        type: "bar",
        data: {
          datasets: this.prepareDatasets(this.roadmap1),
          labels: this.prepareXAxisLabels(),
        },
        options: {
          // layout: {
          //   padding: {
          //     left: 50,
          //     right: 50,
          //     top: 50,
          //     bottom: 50
          //   }
          // },
          legend: {
            display: false, // Autogenerated legend no good
          },
          // tooltips: this.customTooltips(),
          tooltips: {
            enabled: false,
          },
          // annotation: {
          //   annotations: this.prepareAnnotations()
          // },
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            filler: {
              propagate: false,
            },
          },
          scales: {
            xAxes: [{
              id: "years",
              type: "category",
              position: "bottom",
              stacked: true,
              display: !(this.roadmap2 || mobile),
            }],
            yAxes: [{
              id: "line",
              type: "linear",
              position: "right",
              scaleLabel: {
                display: !(this.roadmap2 || mobile),
                labelString: this.$t("roadmaps_punchcard.chart.y-axes-right"),
              },
              ticks: {
                reverse: false,
                max: 25, // to make space above actual max-value
                min: -100,
                stepSize: 100,
                fontColor: "#ccc",
                callback: function(value) {
                  if (Number(value) === 0) return "  0";
                  if (Number(value) === -100) return `${value}%`;
                },
              },
            }, {
              id: "bar",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: !(this.roadmap2 || mobile),
                labelString: this.$t("roadmaps_punchcard.chart.y-axes-left"),
              },
              stacked: true,
              ticks: {
                max: maxBar * 1.25, // to make space above actual max-value
                beginAtZero: true,
                fontColor: "#ccc",
                stepSize: maxBar / 2,
                callback: function(value, index, ticks) {
                  if (Number(value) === maxBar || Number(value) === maxBar / 2) {
                    return `${Math.ceil(Number(value)).toLocaleString()}`;
                  }
                },
              },
            }],
          },
        },
      });

      if (this.roadmap2) {
        // roadmap2
        const context2 = this.$refs.chart2.getContext("2d");
        const maxBar2 = this.prepareBarData(this.roadmap2)[0];
        chart2 = new Chart(context2, {
          type: "bar",
          data: {
            datasets: this.prepareDatasets(this.roadmap2),
            labels: this.prepareXAxisLabels(),
          },
          options: {
            // layout: {
            //   padding: {
            //     left: 50,
            //     right: 50,
            //     top: 50,
            //     bottom: 50
            //   }
            // },
            legend: {
              display: false, // Autogenerated legend no good
            },
            // tooltips: this.customTooltips(),
            tooltips: {
              enabled: false,
            },
            // annotation: {
            //   annotations: this.prepareAnnotations()
            // },
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
              filler: {
                propagate: false,
              },
            },
            scales: {
              xAxes: [{
                id: "years",
                type: "category",
                position: "bottom",
                stacked: true,
                display: !this.roadmap2,
              }],
              yAxes: [{
                id: "line",
                type: "linear",
                position: "right",
                scaleLabel: {
                  display: !this.roadmap2,
                  labelString: this.$t("roadmaps_punchcard.chart.y-axes-right"),
                },
                ticks: {
                  reverse: false,
                  max: 25, // to make space above actual max-value
                  min: -100,
                  stepSize: 100,
                  fontColor: "#ccc",
                  callback: function(value) {
                    if (Number(value) === 0) return "  0";
                    if (Number(value) === -100) return `${value}%`;
                  },
                },
              }, {
                id: "bar",
                type: "linear",
                position: "left",
                scaleLabel: {
                  display: !this.roadmap2,
                  labelString: this.$t("roadmaps_punchcard.chart.y-axes-left"),
                },
                stacked: true,
                ticks: {
                  max: maxBar * 1.25, // to make space above actual max-value
                  beginAtZero: true,
                  fontColor: "#ccc",
                  stepSize: maxBar,
                  callback: function(value) {
                    if (Number(value) === maxBar2) {
                      return `${Number(value).toLocaleString()}`;
                    }
                  },
                },
              }],
            },
          },
        });
      }
    },
    prepareAnnotationData(field) {
      return {
        type: "line",
        scaleID: "years",
        value: this.roadmap1[field],
        borderColor: colors.net_zero,
        borderWidth: 2,
        label: {
          enabled: true,
          fontStyle: "normal",
          fontColor: colors.net_zero,
          position: "top",
          cornerRadius: 0,
          color: colors.net_zero,
          yAdjust: 1,
          xAdjust: -15,
          content: this.$t(`chart.${field}`),
        },
      };
    },
    prepareAnnotations() {
      let annotations = [];
      // annotations.push(this.prepareAnnotationData('net_zero_year'))
      // annotations.push(this.prepareAnnotationData('year_of_getting_effective'))
      // annotations.push({
      //   type: 'box',
      //   xScaleID: 'years',
      //   yScaleID: 'bar',
      //   x: 1990,
      //   yMin: 1000,
      //   yMax: 2000,
      //   borderColor: colors.net_zero,
      //   fillColor: colors.net_zero,
      //   borderWidth: 2,
      //   label: {
      //     enabled: true,
      //     fontStyle: 'normal',
      //     fontColor: colors.net_zero,
      //     position: 'top',
      //     cornerRadius: 0,
      //     color: colors.net_zero,
      //     yAdjust: 1,
      //     xAdjust: -15,
      //     content: 'hihi'
      //   }
      // })

      return annotations;
    },
    prepareDatasets(roadmap) {
      let datasets = [];
      const barData = this.prepareBarDataSet(roadmap);
      if (barData) {
        barData.forEach(function(value) {
          datasets.push(value);
        });
      }
      const lineData = this.prepareLineDataSet(roadmap);
      if (lineData) {
        lineData.forEach(function(value) {
          datasets.push(value);
        });
      }

      return datasets;
    },
    prepareBarData(roadmap, bound) {
      let data = [];
      let min = 0;

      // emission-values (min if max/min) for bars
      this.defaultYears.forEach(function(defaultYear) {
        let found = false;
        roadmap.ghg_emission.forEach(function(val) {
          let value = null;
          if (val.default_year === defaultYear) {
            if (val.emission != null) {
              value = val.emission;
              if (min === 0) {
                min = value;
              }
            } else {
              value = val.emission_max;
            }
            if (value > min) {
              value = min;
            }
            data.push(value);
            found = true;
          }
        });
        if (!found) {
          data.push(null);
        }
      });

      return data;
    },
    prepareBarDataSet(roadmap) {
      // const prepareColors = (color1, color2) => [...this.prepareXAxisLabels().map(() => color1), ...Array(3).fill(color2)]
      return [
        {
          label: `${this.$t("chart.ghg_emissions")} min (Mt)`,
          data: this.prepareBarData(roadmap, "min"),
          xAxisID: "years",
          yAxisID: "bar",
          stack: "1",
          backgroundColor: [
            colors.gray,
            colors.gray,
            colors.gray_60,
            colors.gray_60,
            colors.gray_60,
          ],
          borderColor: colors.gray_60,
          borderWidth: 0,
        },
      ];
    },
    prepareLineData(roadmap, type) {
      const defaultYears = this.defaultYears;
      let data = [];

      // emission-min line
      if (type === "emission_min") {
        defaultYears.forEach(function(defaultYear) {
          let found = false;
          roadmap.ghg_emission.forEach(function(val) {
            if (val.default_year === defaultYear) {
              if (val.emission != null) {
                data.push(val.emission);
              } else {
                data.push(val.emission_min);
              }
              found = true;
            }
          });
          if (!found) {
            data.push(null);
          }
        });
      } else if (type === "emission_max") { // emission-max line
        defaultYears.forEach(function(defaultYear) {
          let found = false;
          roadmap.ghg_emission.forEach(function(val) {
            if (val.default_year === defaultYear) {
              if (val.emission != null) {
                data.push(val.emission);
              } else {
                data.push(val.emission_max);
              }
              found = true;
            }
          });
          if (!found) {
            data.push(null);
          }
        });
      } else if (type === "reduction_min") { // reduction-min line
        defaultYears.forEach(function(defaultYear) {
          let found = false;
          roadmap.ghg_reduction.forEach(function(val) {
            if (val.default_year === defaultYear) {
              if (val.reduction != null) {
                data.push(Math.round((val.reduction * 100 + Number.EPSILON) * 100) / 100);
              } else {
                data.push(Math.round((val.reduction_min * 100 + Number.EPSILON) * 100) / 100);
              }
              found = true;
            }
          });
          if (!found) {
            if (defaultYear === defaultYears[0]) {
              data.push(0);
            } else {
              data.push(null);
            }
          }
        });
      } else if (type === "reduction_max") { // reduction-max line
        defaultYears.forEach(function(defaultYear) {
          let found = false;
          roadmap.ghg_reduction.forEach(function(val) {
            if (val.default_year === defaultYear) {
              if (val.reduction != null) {
                data.push(Math.round((val.reduction * 100 + Number.EPSILON) * 100) / 100);
              } else {
                data.push(Math.round((val.reduction_max * 100 + Number.EPSILON) * 100) / 100);
              }
              found = true;
            }
          });
          if (!found) {
            if (defaultYear === defaultYears[0]) {
              data.push(0);
            } else {
              data.push(null);
            }
          }
        });
      }

      return data;
    },
    prepareLineDataSet(roadmap) {
      // draw emission min/max and reduction min/max values as hidden lines to use in draw()
      return [
        {
          label: `${this.$t("chart.ghg_reduction_rate")} min (%)`,
          data: this.prepareLineData(roadmap, "emission_min"),
          type: "line",
          lineTension: 0,
          xAxisID: "years",
          yAxisID: "bar",
          fill: false,
          borderColor: colors.linePrimary,
          spanGaps: true,
          hidden: true,
        },
        {
          label: `${this.$t("chart.ghg_reduction_rate")} min (%)`,
          data: this.prepareLineData(roadmap, "emission_max"),
          type: "line",
          lineTension: 0,
          xAxisID: "years",
          yAxisID: "bar",
          fill: false,
          borderColor: colors.linePrimary,
          spanGaps: true,
          hidden: true,
        },
        {
          label: `${this.$t("chart.ghg_reduction_rate")} min (%)`,
          data: this.prepareLineData(roadmap, "reduction_min"),
          type: "line",
          lineTension: 0,
          xAxisID: "years",
          yAxisID: "line",
          fill: false,
          borderColor: colors.linePrimary,
          spanGaps: true,
          hidden: true,
        },
        {
          label: `${this.$t("chart.ghg_reduction_rate")} min (%)`,
          data: this.prepareLineData(roadmap, "reduction_max"),
          type: "line",
          lineTension: 0,
          xAxisID: "years",
          yAxisID: "line",
          fill: false,
          borderColor: colors.linePrimary,
          spanGaps: true,
          hidden: true,
        },
      ];
    },
    prepareXAxisLabels() {
      let years = [];
      let roadmap = this.roadmap1;

      this.defaultYears.forEach(function(defaultYear) {
        let found = false;
        roadmap.ghg_emission.forEach(function(val) {
          if (val.default_year === defaultYear) {
            years.push(val.year);
            found = true;
          }
        });
        if (!found) {
          years.push(defaultYear);
        }
      });

      years = Array.from(years).sort();
      years[0] = this.$t("roadmaps_punchcard.chart.x-axes-reference") + ": " + years[0];
      years[1] = this.$t("roadmaps_punchcard.chart.x-axes-inventory") + ": " + years[1];
      for (let i = 2; i < years.length; i++) {
        years[i] = this.$t("roadmaps_punchcard.chart.x-axes-reduction-target") + ": " + years[i];
      }
      return years;
    },
    customTooltips() {
      /* I copy pasted this from somewhere! [lb] */
      return {
        // Disable the on-canvas tooltip
        enabled: false,

        custom: function(tooltipModel) {
          /* I copy pasted this from somewhere! [lb] */

          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            let titleLines = tooltipModel.title || [];
            let bodyLines = tooltipModel.body.map(getBody);

            // TODO format bodylines

            let innerHtml = "<thead>";

            titleLines.forEach(function(title) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            innerHtml += "</thead><tbody>";

            bodyLines.forEach(function(body, i) {
              let colors = tooltipModel.labelColors[i];
              let style = "background:" + colors.backgroundColor;
              style += "; border-color:" + colors.borderColor;
              style += "; border-width: 2px";
              style += "; font-family: 'Avenir', Helvetica, Arial, sans-serif";
              let span = '<span style="' + style + '"></span>';
              innerHtml += "<tr><td>" + span + body + "</td></tr>";
            });
            innerHtml += "</tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          let position = chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.zIndex = 1401;
          tooltipEl.style.color = "white";
          tooltipEl.style.backgroundColor = "rgba(0,0,0,0.5)";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.fontFamily = "Avenir";
        },
      };
    },
  },
  data: () => ({
    defaultYears: [1990, 2018, 2030, 2040, 2050],
  }),
};
</script>

<style scoped lang="scss">
  #chart {
    padding-bottom: 0.5em;
  }
  #chart2 {
    padding-bottom: 0.5em;
  }
</style>
