export const colors = {
  backgroundPrimaryPast: "rgba(0, 90, 154, 0.7)",
  backgroundPrimary: "rgba(0, 90, 154, 0.5)",
  backgroundPrimaryMax: "rgba(0, 90, 154, 0.0)",
  borderPrimary: "rgba(0, 90, 154, 1)",
  borderPrimaryMax: "rgba(0, 90, 154, 0.8)",
  linePrimary: "rgba(0, 90, 154, 1)",
  backgroundOpponentPast: "rgba(148, 193, 46, 0.7)",
  backgroundOpponent: "rgba(148, 193, 46, 0.4)",
  backgroundOpponentMax: "rgba(148, 193, 46, 0.0)",
  borderOpponent: "rgba(148, 193, 46, 1)",
  borderOpponentMax: "rgba(148, 193, 46, 0.8)",
  lineOpponent: "rgba(148, 193, 46, 1)",
  net_zero: "rgba(0, 255, 0, 1)",

  transparent: "rgba(0, 0, 0, 0)",
  white: "rgba(255, 255, 255, 1)",

  blue: "rgba(0, 90, 160 ,1)",
  blue_80: "rgba(0, 90, 160 , 0.8)",
  blue_60: "rgba(0, 90, 160 , 0.6)",
  blue_40: "rgba(0, 90, 160 , 0.4)",
  turquoise: "rgba(67, 176, 170, 1)",
  turquoise_80: "rgba(67, 176, 170, 0.8)",
  turquoise_60: "rgba(67, 176, 170, 0.6)",
  turquoise_40: "rgba(67, 176, 170, 0.4)",
  orange: "rgba(247, 172, 76, 1)",
  orange_80: "rgba(247, 172, 76, 0.8)",
  orange_60: "rgba(247, 172, 76, 0.6)",
  orange_40: "rgba(247, 172, 76, 0.4)",
  red: "rgba(229, 120, 114, 1)",
  red_80: "rgba(229, 120, 114, 0.8)",
  red_60: "rgba(229, 120, 114, 0.6)",
  red_40: "rgba(229, 120, 114, 0.4)",
  gray: "rgba(112, 112, 112, 1)",
  gray_80: "rgba(112, 112, 112, 0.8)",
  gray_60: "rgba(112, 112, 112, 0.6)",
  gray_40: "rgba(112, 112, 112, 0.4)",

};
