import { getMeta } from "@/tools";

const BASE_URL = getMeta("api-url-" + process.env.NODE_ENV);

const ROADMAPS_URL = BASE_URL + "/roadmaps/";
const PARTNER_URL = BASE_URL + "/partner/";
const METHOD_TYPES_URL = BASE_URL + "/method_types/";
const METHODS_URL = BASE_URL + "/contributions/";
const TOPIC_TYPES_URL = BASE_URL + "/topic_types/";
const SCENARIO_TYPES_URL = BASE_URL + "/scenario_types/";
const SECTORS_URL = BASE_URL + "/sectors/";
const GHGS_URL = BASE_URL + "/ghgs/";
const NEGATIVE_EMISSIONS_URL = BASE_URL + "/negative_emissions/";
const AMOUNT_URL = BASE_URL + "/amount/";
const SOCIAL_PARTICIPATION_URL = BASE_URL + "/social_participation/";
const LANGUAGES_URL = BASE_URL + "/languages/";
const COUNTRIES_URL = BASE_URL + "/countries/";
const GLOSSARY_URL = BASE_URL + "/glossary/";


let roadmaps = [];
let partner = [];
let methodTypes = [];
let methods = [];
let topicTypes = [];
let scenarioTypes = [];
let sectors = [];
let ghgs = [];
let negativeEmissions = [];
let amount = [];
let socialParticipation = [];
let languages = [];
let countries = [];
let glossary = [];

export const fetchRoadmaps = cb => {
  fetch(ROADMAPS_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      roadmaps = obj.results;
      cb(roadmaps);
    });
};

export const fetchPartner = cb => {
  fetch(PARTNER_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      partner = obj.results;
      cb(partner);
    });
};

export const fetchMethodTypes = cb => {
  fetch(METHOD_TYPES_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      methodTypes = obj.results;
      cb(methodTypes);
    });
};

export const fetchMethods = cb => {
  fetch(METHODS_URL, { credentials: "include" })
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      methods = obj.results;
      cb(methods);
    });
};

export const fetchTopicTypes = cb => {
  fetch(TOPIC_TYPES_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      topicTypes = obj.results;
      cb(topicTypes);
    });
};

export const fetchScenarioTypes = cb => {
  fetch(SCENARIO_TYPES_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      scenarioTypes = obj.results;
      cb(scenarioTypes);
    });
};

export const fetchSectors = cb => {
  fetch(SECTORS_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      sectors = obj;
      cb(sectors);
    });
};

export const fetchGHGs = cb => {
  fetch(GHGS_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      ghgs = obj.results;
      cb(ghgs);
    });
};

export const fetchNegativeEmissons = cb => {
  fetch(NEGATIVE_EMISSIONS_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      negativeEmissions = obj.results;
      cb(negativeEmissions);
    });
};

export const fetchAmount = cb => {
  fetch(AMOUNT_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      amount = obj.results;
      cb(amount);
    });
};

export const fetchSocialParticipation = cb => {
  fetch(SOCIAL_PARTICIPATION_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      socialParticipation = obj.results;
      cb(socialParticipation);
    });
};

export const fetchLanguages = cb => {
  fetch(LANGUAGES_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      languages = obj.results;
      cb(languages);
    });
};

export const fetchCountries = cb => {
  fetch(COUNTRIES_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      countries = obj;
      cb(countries);
    });
};

export const fetchGlossary = cb => {
  fetch(GLOSSARY_URL)
    .then((response) => {
      return response.json();
    })
    .then((obj) => {
      glossary = obj.results;
      cb(glossary);
    });
};
