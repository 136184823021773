<template>
  <div class="panel">
    <div class="description">
      <RoadmapPunchcardLabel
        name_de="Welche negativen Emissionspotentiale werden berücksichtigt?"
        name_en="Which negative emission potentials are taken into account?"
      />
    </div>
    <span v-for="obj in negativeEmissions" :key="obj.id">
      <RoadmapPunchcardRow
        :values="[roadmap.negative_emission.includes(obj.id)]"
        :values2="roadmap2? [roadmap2.negative_emission.includes(obj.id)]:null"
        :roadmap2="roadmap2"
        :type="2"
        :name_de="obj.name_en_GB"
        :name_en="obj.name_en_GB"
        :link="getGlossaryLink(obj.glossary)"
      />
    </span>
    <RoadmapPunchcardRow
        :values="[roadmap.negative_emission.length === 0]"
        :values2="roadmap2? [roadmap2.negativeEmissions.length > 0]:null"
        :roadmap2="roadmap2"
        :type="2"
        name_de="nicht spezifiziert"
        name_en="not specified"
      />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";
import { getGlossaryLink } from "@/tools";

export default {
  name: "RoadmapPunchcardAccordion",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
    negativeEmissions: Array,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },
  methods: {
    getGlossaryLink: getGlossaryLink,
    async initialize() {
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
