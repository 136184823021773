import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=97d3df46&scoped=true&"
import script from "./Accordion.vue?vue&type=script&lang=js&"
export * from "./Accordion.vue?vue&type=script&lang=js&"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=97d3df46&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97d3df46",
  null
  
)

export default component.exports