<!--TODO: Verhindern dass logos abgeschnitten werden, weniger platz verschenken-->
<template>
  <div class="map-sidemenu d-flex" :class="{ 'open': showSidemenu }">
    <div class="map-sidemenu-inner">
      <span v-if="!closed || showSidemenu || forceArrow" class="map-sidemenu-toggle cursor-pointer" @click="toggle()">
        <i :class="getIconName"></i>
      </span>

      <div class="map-sidemenu-text" v-if="!loading" ref="text">
        <slot></slot>
      </div>
      <div class="loading" v-else>
        Loading
        <!-- TODO: Add styling for loading here -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapSidemenu",

  props: {
    closed: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    showSidemenu: true,
  }),

  computed: {
    getIconName() {
      let iconName = "icon-angle-left";

      if (this.showSidemenu) {
        iconName = "icon-enlarge";
      }
      return iconName;
    },
  },

  methods: {
    resetScroll() {
      this.$refs.text.scrollTop = 0;
    },
    toggle() {
      this.showSidemenu = !this.showSidemenu;
      this.$emit("toggle", this.showSidemenu);
    },
    open() {
      this.showSidemenu = true;
    },
    scrollToTop() {
      this.$refs.text.scrollTop = 0;
    },
  },

  created() {
    this.showSidemenu = !this.closed;
  },
};
</script>

<style scoped lang="scss">

.map-sidemenu {
  width: 83%;
  max-width: rem(890);
  position: fixed;
  top: rem($header-height);
  right: 0;
  bottom: 0;
  z-index: 8999;
  transform: translateX(100%);
  transition: box-shadow 0.3s ease, transform 0.5s ease;

  &.open {
    transform: translateX(0);

    .map-sidemenu-inner {
      box-shadow: 0 -1px 24px rgb(0 0 0 / 25%);
    }
  }
}

.map-sidemenu-inner {
  flex: 1;
  position: relative;
  background-color: #fff;
  box-shadow: none;
}

.map-sidemenu-text {
  height: 100%;
  padding-top: rem(25);
  overflow-y: auto;
}

.map-sidemenu-toggle {
  width: rem(44);
  height: rem(44);
  position: absolute;
  top: rem($map-overlay-top-position);
  left: -(rem(20));
  transform: translateX(-100%);
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);

  &:hover {
    i {
      color: $font-color;
    }
  }

  > i {
    color: $map-icon-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(26);
  }
}

@media screen and (max-width: rem($break-sm)) {
  .map-sidemenu-toggle {
    top: rem(8);
    left: -(rem(5));
  }
}

.light svg path{
  stroke-width: 0.1px !important;
}

</style>
