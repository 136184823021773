<template>
  <div id="info">
    <h1>404 - Page Not Found</h1>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped>
    #info{
      position: absolute;
      top: 48%;
      left: 50%;
    }
</style>
