<template>
  <div class="page scenario-intro">
    <frontpage-banner-slider :slides="langSlider"></frontpage-banner-slider>

    <frontpage-section :title="$t('link.scenarios')">
      <DynText txt="scenario_text"></DynText>
      <div v-for="scenario_type in scenarioTypes" :key="scenario_type.id" aria-atomic="true">
        <x-accordion :title="getItemAttr(scenario_type, 'name', $i18n.locale)" class="mb-4">
          <div class="bullet-list" v-for="scenario in scenarios" :key="scenario.id">
            <div class="bullet-list-item d-flex full" v-if="scenario_type.id === scenario.scenario_type && (scenario.contribution_affiliation === 'Pathway' || scenario.contribution_affiliation === 'Pfad')">
              <div class="mr-2">
                <i v-if="loggedIn" class="icon-play" :style="{'color': scenario.contribution_status_color}"></i>
                <i v-else class="icon-play"></i>
              </div>
              <router-link :title="'Status: ' + scenario.contribution_status" :to="{ name: 'contribution', params: { id: scenario.id } }" v-html="getItemAttr(scenario, 'title', $i18n.locale)"></router-link>
            </div>
          </div>
        </x-accordion>
      </div>
    </frontpage-section>
    <SubtleFooter></SubtleFooter>
  </div>
</template>

<script>
import FrontpageBannerSlider from "@/components/frontpage/BannerSlider";
import FrontpageSection from "@/components/frontpage/Section";
import XAccordion from "@/components/core/Accordion";
import DynText from "@/components/DynText";
import { fetchMethods, fetchScenarioTypes } from "@/api";
import { getItemAttr, decodeEntities, isLoggedIn } from "@/tools";
import SubtleFooter from "@/components/SubtleFooter";

export default {
  name: "ScenarioIntro",

  components: {
    FrontpageBannerSlider,
    FrontpageSection,
    XAccordion,
    DynText,
    SubtleFooter,
  },

  methods: {
    getItemAttr: getItemAttr,
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      let panel = event.target.nextSibling;

      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    showMethodDetail(methodId, isMap) {
      if (isMap === 1) {
        this.$emit("showDetail", methodId);
      } else {
        this.$emit("showScenarioStoryDetail", methodId);
      }
    },
    async initialize() {
      fetchScenarioTypes(scenarioTypes => {
        this.scenarioTypes = scenarioTypes;
      });
      fetchMethods(scenarios => {
        this.scenarios = scenarios;
      });
    },
  },

  data: () => ({
    loggedIn: false,
    scenarioTypes: null,
    scenarios: null,
    bullet_image: require("@/assets/bullet.png"),
    slides: {
      de: [
        {
          id: 1,
          image: require("@/assets/img/slider/iStock-1097184316_c_kokouu.jpg"),
          text: `
            <div class="container">
              <span class="prominent">NETTO-NULL-2050 WEB-ATLAS</span>
              <span>STRATEGIEN UND NEUE WEGE</span>
              <span> ZUR REDUZIERUNG, Vermeidung</span>
              <span>UND ENTNAHME VON CO<sub>2</sub> - EMISSIONEN</span>
              <span>IN DEUTSCHLAND.</span>
            </div>`,
        },
      ],
      en: [
        {
          id: 1,
          image: require("@/assets/img/slider/iStock-1097184316_c_kokouu.jpg"),
          text: `
              <div class="container">
                  <span>Strategies and new Ways</span>
                  <span>For the reduction of</span>
                  <span>CO<sub>2</sub> - Emissions in germany.</span>
              </div>
          `,
        },
      ],
    },
  }),

  async created() {
    this.loggedIn = await isLoggedIn();
    this.initialize();
  },

  computed: {
    langSlider() {
      if (this.$i18n.locale.startsWith("de")) {
        return this.slides.de;
      } else {
        return this.slides.en;
      }
    },
  },
};
</script>

<style lang="scss">
.bullet-list-item > a p{
  display: inline;
}

.status{
  display: inline;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.status_border{
  border: 3px solid;
  padding: 5px;
}
</style>
