<template>
  <span class="roadmap_punchcard_tfn">
    <template v-if="obj === true">
      <span v-if="this.$i18n.locale === 'en'">
        <img src="../../assets/icons/considered.png" class="icon_considered" :title="name_en">
      </span>
      <span v-else>
        <img src="../../assets/icons/considered.png" class="icon_considered" :title="name_de">
      </span>
    </template>
    <template v-else-if="obj === false">
      <span v-if="this.$i18n.locale === 'en'">
        <img src="../../assets/icons/not_considered.png" class="icon_considered" :title="name_en">
      </span>
      <span v-else>
        <img src="../../assets/icons/not_considered.png" class="icon_considered" :title="name_de">
      </span>
    </template>
    <template v-else>
      <span v-if="this.$i18n.locale === 'en'">
        <img src="../../assets/icons/none.png" class="icon_considered" :title="name_en">
      </span>
      <span v-else>
        <img src="../../assets/icons/none.png" class="icon_considered" :title="name_de">
      </span>
    </template>
  </span>
</template>

<script>
export default {
  name: "RoadmapPunchcardTFN",
  props: {
    obj: Boolean,
    name_de: String,
    name_en: String,
  },
};
</script>

<style scoped lang="scss">
  .roadmap_punchcard_tfn {
    margin-right: 0.15em;
  }
</style>
