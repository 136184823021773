<template>
  <div class="page glossary-page">
    <frontpage-section :title="$t('menu.glossary')">
      <Breadcrumbs></Breadcrumbs>
      <div v-for="item in glossary" :key="item.id" class="glossary">
          <span :id="'item' + item.id"><h2 v-html="getItemAttr(item, 'name', $i18n.locale)"></h2></span>
          <div v-html="getItemAttr(item, 'description', $i18n.locale)"></div>
      </div>
    </frontpage-section>
    <SubtleFooter></SubtleFooter>
  </div>

</template>

<script>

import FrontpageSection from "@/components/frontpage/Section";
import SubtleFooter from "@/components/SubtleFooter";
import Breadcrumbs from "@/components/Breadcrumbs";
import { fetchGlossary } from "@/api";
import { getItemAttr, scrollToAnchor } from "@/tools";

export default {
  name: "Glossary",
  components: {
    FrontpageSection,
    SubtleFooter,
    Breadcrumbs,
  },
  data: () => ({
    glossary: null,
    slides: [
      {
        id: 1,
        image: require("@/assets/Titelseite_Blatt.jpg"),
        text: `
            <div class="container text-left">
                <span>STRATEGIEN UND NEUE WEGE</span>
                <span>ZUR REDUZIERUNG VON</span>
                <span>CO<sub>2</sub> - EMISSIONEN IN DEUTSCHLAND.</span>
            </div>
        `,
      },
    ],
  }),
  mounted() {
    fetchGlossary(glossary => {
      this.glossary = glossary;
      this.$nextTick(function() {
        this.scrollToAnchor(this.$route.hash);
      });
    });
  },
  methods: {
    getItemAttr: getItemAttr,
    scrollToAnchor: scrollToAnchor,

    // getOrderedGlossary(lang) {
    //   const x = this.glossary.copy().sort(function(a, b) {
    //     return getItemAttr(b, "name", lang) - getItemAttr(a, "name", lang);
    //   });
    //   console.log("?", x);
    //   return x;
    // },
  },
};
</script>

<style lang="scss" scoped>
.glossary-page{
  margin-top: 3em;
}

.glossary {
  border-bottom: rem(1) solid var(--x-gray-400);
  padding-bottom: rem(10);

  > span {
    display: block;
    padding-top: rem(20);
  }
}

h2{
  color: $font-color;
}
</style>
