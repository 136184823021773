<template>
  <footer :class="{map: map}">
    <div class="footer-links">
      <span class="footer-item">
        <a :href="$t('footer.imprint_link')">{{ $t('footer.imprint')}}</a>
      </span>
      <span class="footer-item">
        <a :href="$t('footer.privacy_link')">{{ $t('footer.privacy')}}</a>
      </span>
      <span class="footer-item">
        <a :href="$t('footer.accessibility_link')">{{ $t('footer.accessibility')}}</a>
      </span>
      <span class="footer-item">
        <a href="mailto:netto-null-2050.atlas@hereon.de">{{$t('footer.contact')}}</a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SubtleFooter",
  props: {
    map: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
footer{
  width: 100%;
  //height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.footer-item{
  margin-right: 20px;
}

.footer-links{
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding-left: 22%;
  padding-right: 78%;
}

.map{
  background-color: white;
  position: absolute;
  top: 95%;
  .footer-links{
    padding-left: 27%;
    padding-right: 73%;
  }
}

@media screen and (max-width: 481px){
  .footer-links{
    flex-direction: column;
  }

  .map{
    position: relative;
    top: 0;
  }
}
</style>
