<template>
  <div class="page method-map d-flex flex-column">
    <map-sidemenu ref="sidemenue">
      <span v-if="method.title">
        <frontpage-section :title="getItemAttr(method, 'title', $i18n.locale)" breadcrumbs>
          <template v-slot:above_title>
            <Breadcrumbs class="sidebar-menu-breadcrumb" :current-display-name="getItemAttr(method, 'title_short', $i18n.locale)" :override-parent="backlink"></Breadcrumbs>
          </template>
          <small id="author">{{singleAuthor}}</small>
          <div id="accordions-wrapper">
            <x-accordion :title="$t('method_detail.short_intro')" turqois class="mb-4">
              <div class="bullet-list" id="description" v-html="getItemAttr(method, 'description', $i18n.locale)"></div>
            </x-accordion>
            <x-accordion v-show="topicType.id != 3 || (method.expert_page && method.expert_page !== '')" v-for="topicType in topicTypes" v-bind:key="topicType.id" :title="topicTypeLang(topicType)" closed :level="topicType.level">
              <!-- "Wer es genau wissen will"-->
              <div v-if="topicType.id == 3">
                <div v-if="getItemAttr(method, 'expert_page', $i18n.locale)">
                  <p>
                    <router-link v-if="method.expert_page && method.expert_page !== ''" :to="{ name: 'moreInfo', params: { id: method.id } }"  target="_blank">{{$t('method_detail.more_info_link')}}</router-link>
                  </p>
                  <div class="topic-title"><strong v-html="$t('method_detail.more_info_text')"></strong></div>
                  <div class="topic-text" v-html="getItemAttr(method, 'further_information', $i18n.locale)"></div>
                </div>
              </div>
              <div v-for="topic in method.topics" :key="topic.id">
                <div v-if="topic.type_id === topicType.id">
                  <div class="topic-title"><strong>{{topicTitleLang(topic)}}</strong></div>
                  <div class="topic-text" v-html="getItemAttr(topic, 'text', $i18n.locale)"></div>
                </div>
              </div>
            </x-accordion>
            <XAccordion id="header-accordion" class="partner-accordion mb-4" :title="$t('method_detail.authors')" closed>
              <div class="bullet-list">
                <div class="bullet-list-item d-flex">
                  <div class="mr-2"><i class="icon-play"></i></div>
                  <router-link :to="{path: '/partners/#' + method.first_author.partner}" target="_blank">{{method.first_author.name}}</router-link>
                </div>
              </div>
              <div class="bullet-list" v-for="author in method.further_authors" :key="author">
                <div class="bullet-list-item d-flex">
                  <div class="mr-2"><i class="icon-play"></i></div>
                  <router-link :to="{path: '/partners/#' + author.partner}" target="_blank">{{author.name}}</router-link>
                </div>
              </div>
            </XAccordion>
          </div>
        </frontpage-section>
      </span>
      <SubtleFooter map></SubtleFooter>
    </map-sidemenu>
    <div class="map-switcher">
      <div v-for="(map, index) in method.contribution_maps" :key="index" class="map-switch-item p-1" v-bind:class="getMapSwitcherClass(index)" title="zu dieser Karte wechseln" @click="chooseLayer(index)">
        {{map.name}}
      </div>
    </div>
    <select class="map-switcher-mobile" @change="chooseLayer($event.target.value)">
      <option v-for="(map, index) in method.contribution_maps" :value="index" v-bind:key="index">{{map.name}}</option>
    </select>
    <div class="opacity-switch">
      <div class="op-label-top">
        <i class="icon-circle"></i>
      </div>
      <input type="range" class="range vertical-lowest-first round" min="0.08" max="1" step="0.01" v-model="mapOpacity" @change="setOpacity" v-tooltip="$t('methods.opacity')">
      <div class="op-label">
        <i class="icon-circle-o"></i>
      </div>
    </div>
    <PinMap :lat="52" :lon="19" :layerUrl="overlayUrl" :opacity="mapOpacity" ref="map"></PinMap>
  </div>
</template>

<script>
import PinMap from "@/components/map/PinMap";
import MapSidemenu from "@/components/map/MapSidemenu";
import FrontpageSection from "@/components/frontpage/Section";
import XAccordion from "@/components/core/Accordion";
import SubtleFooter from "@/components/SubtleFooter";
import Breadcrumbs from "@/components/Breadcrumbs";
import { fetchTopicTypes } from "@/api";
import { getItemAttr } from "@/tools";

export default {
  name: "MethodMap",
  props: {
    contrib_id: {
      type: String,
      required: false,
    },
  },
  components: {
    PinMap,
    MapSidemenu,
    FrontpageSection,
    XAccordion,
    SubtleFooter,
    Breadcrumbs,
  },
  data() {
    return {
      method: {},
      topicTypes: {},
      selectedMap: 0,
      mapOpacity: 1,
    };
  },
  methods: {
    getItemAttr: getItemAttr,
    setOpacity() {
      this.$refs.map.renderLayers(this.selectedMap, this.mapOpacity);
    },
    chooseLayer(map) {
      this.$refs.map.renderLayers(map);
      this.selectedMap = map;
      this.mapOpacity = 1;
    },
    topicTypeLang(type) {
      if (this.$i18n.locale === "en") {
        return type.topic_type_en_GB;
      } else {
        return type.topic_type;
      }
    },
    topicTextLang(text) {
      if (this.$i18n.locale === "en") {
        return text.topic_text_en_GB;
      } else {
        return text.topic_text;
      }
    },
    topicTitleLang(title) {
      if (this.$i18n.locale === "en") {
        return title.title_en_GB;
      } else {
        return title.title;
      }
    },

    getMapSwitcherClass(index) {
      if (this.selectedMap === index) {
        return {
          "active": true,
        };
      }
    },
  },

  computed: {
    trueId() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    backlink() {
      if (this.method.contribution_affiliation === "Method") {
        return { disp: this.$t("link.methods"), name: "methodIntro", last: false };
      } else {
        return { disp: this.$t("link.scenarios"), name: "scenarioIntro", last: false };
      }
    },
    overlayUrl() {
      return this.$apiURL + "/contributions/" + this.trueId + "/";
    },
    singleAuthor() {
      var first = this.method.first_author;
      var further = this.method.further_authors;

      if (further.length === 0) {
        if (first) {
          return first.name;
        } else {
          return "";
        }
      } else {
        return first.name + " et al";
      }
    },
  },

  created() {
    fetch(this.$apiURL + "/contributions/" + this.trueId + "/", { credentials: "include" }).then(response => {
      return response.json();
    }).then(method => {
      this.method = method;
    });
    fetchTopicTypes(topicTypes => {
      this.topicTypes = topicTypes;
    });
  },

  updated() {
    let json = JSON.stringify({
      text: this.method.expert_page,
      text_de: this.method.expert_page_de,
      text_en_GB: this.method.expert_page_en_GB,
      title: this.method.title,
      title_de: this.method.title_de,
      title_en_GB: this.method.title_en_GB,
    });
    localStorage.setItem("expert_" + this.method.id, json);
  },
};
</script>

<style scoped lang="scss">
#map-select {
  float: right;
}

#accordions-wrapper {
  margin-top: 1em;
}

.map-switcher {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: rem(8);
  position: absolute;
  top: rem($map-overlay-top-position);
  left: rem(60);
  max-width: 80em;
  z-index: 500;
  opacity: 0.7;
}

@media screen and (max-width: 1415px){
  .map-switcher {
    display: none;
  }
}

.map-switch-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: rem(50);
  width: 15em;
  border: rem(2) solid rgba(0,0,0,0.4);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.2;
  border-radius: rem(4);
  user-select: none;

  &.active {
    color: #fff;
    background-color: #055aa0;
  }
}

.map-switcher-mobile {
  position: absolute;
  top: rem($map-overlay-top-position);
  left: rem(60);
  background-color: #fff;
  font-size: rem(16);
  border: 2px solid rgba(0,0,0,0.3);
  padding: rem(5);
  border-radius: rem(4);
  z-index: 500;

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 1415px){
  .map-switcher-mobile{
    display: none;
  }
}

.opacity-switch{
  position: fixed;
  z-index: 500;
  top: rem(145);
  left: rem(-25);
}

.op-label {
  color: #000000;
  font-size: 15pt;
  margin-left: rem(42);
  margin-top: rem(35);
}

.op-label-top{
  margin-left: rem(42);
  margin-bottom: rem(35);
  font-size: 15pt;
  color: #000000;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

@media screen and (max-width: 1174px){
  .mobile-blur {
    filter: blur(10px);
  }
}
</style>
