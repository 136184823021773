/* global app */
import { countryList } from "./constants/countryList";

const countries = require("i18n-iso-countries");

export function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }

  return "";
}

const BASE_URL = getMeta("api-url-" + process.env.NODE_ENV);

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

let countryDict = null;

// Get country name from geoJSON feature
export const getName = feature => {
  const code = feature.code ? feature.code : feature.properties.ISO_A2;

  if (code.toLowerCase() === "eu") {
    return app.$i18n.locale.startsWith("de") ? "Europäische Union" : "European Union";
  } else if (code.toLowerCase() === "un") {
    return app.$i18n.locale.startsWith("de") ? "Vereinte Nationen" : "United Nations";
  } else {
    const langShort = (app.$i18n.locale).substring((app.$i18n.locale).indexOf("-") + 1).toLowerCase();
    return countries.getName(code, langShort);
  }
};

export const getCountryDict = entries => {
  if (!countryDict) {
    if (!entries) return;
    const countriesWithData = entries.map(e => e.country);
    countryDict = countryList.features.filter(c =>
      countriesWithData.includes(c.properties.ISO_A2)
    ).map(c => {
      return {
        code: c.properties.ISO_A2,
        name: c.properties.SOVEREIGNT,
      };
    }).sort((a, b) => a.name.localeCompare(b.name));
  }
  return countryDict;
};

// Get country flag from geoJSON feature
export const getFlagURL = feature => {
  if (!feature.properties || ["EU", "UN"].includes(feature.properties.ISO_A2)) {
    const extraFlags = require.context("./assets/", false, /\.png$/);
    if (feature.code.toLowerCase() === "eu") return extraFlags("./eu.png");
    if (feature.code.toLowerCase() === "un") return extraFlags("./un.png");
    return;
  }

  const countryCode = feature.properties.ISO_A2.slice(0, 2).toLowerCase();
  return `https://www.geonames.org/flags/x/${countryCode}.gif`;
};

// Get country code from geoJSON feature
export const getCode = feature => feature.properties.ISO_A2;

export const notify = (text, duration = 5000, ignoreDuplicates = false) => {
  if (duration === true) duration = 999999; // forever
  app.$notify({
    text,
    width: "500px",
    duration,
    ignoreDuplicates,
  });
};

// get attribute "attr" from object "item" for language "lang"
export const getItemAttr = (item, attr, lang, use_de = false) => {
  if (lang.startsWith("de")) {
    if (use_de) {
      return item[attr + "_de"];
    } else {
      return item[attr];
    }
  } else {
    if (item[attr + "_en_GB"] !== null && item[attr + "_en_GB"] !== "") {
      return item[attr + "_en_GB"];
    } else {
      return item[attr];
    }
  }
};


export const getGlossaryLink = (glossary_id) => {
  if (glossary_id) {
    return "glossary#item" + glossary_id;
  } else {
    return null;
  }
};

export const scrollToAnchor = (hash) => {
  const element = document.querySelector(hash);
  const header_element = document.querySelector("header");
  const scroll_point = (element.offsetTop - header_element.offsetHeight) - 150;

  window.scrollTo(0, scroll_point);
};

export function decodeHTMLEntities(str) {
  // strip script/html tags
  str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, "");
  str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, "");
}

export async function isLoggedIn() {
  let data = await fetch(BASE_URL + "/is_authenticated/", { credentials: "include" });
  let json = await data.json();
  return json.authenticated;
}
