import { render, staticRenderFns } from "./Element.vue?vue&type=template&id=561535c7&scoped=true&"
import script from "./Element.vue?vue&type=script&lang=js&"
export * from "./Element.vue?vue&type=script&lang=js&"
import style0 from "./Element.vue?vue&type=style&index=0&id=561535c7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561535c7",
  null
  
)

export default component.exports