<template>
  <span v-if="this.$i18n.locale === 'en'">
    <span v-html="name_en"></span>
  </span>
  <span v-else>
    <span v-html="name_de"></span>
  </span>
</template>

<script>

export default {
  name: "RoadmapPunchcardLabel",
  components: {
  },
  props: {
    name_de: String,
    name_en: String,
  },
};
</script>

<style scoped lang="scss">
  .green {
    color: green;
  }
  .red {
    color:red;
  }
</style>
