<template>
  <div class="panel">
    <div>
      <RoadmapPunchcardLabel
        name_de="Welche statistischen Kenngrößen charakterisieren die Roadmap?"
        name_en="Which statistic values are characterizing the roadmap?"
      />
    </div>
    <RoadmapPunchcardRow
      :values="[String(roadmap.roadmap_length)]"
      :values2="roadmap2? [String(roadmap2.roadmap_length)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Seitenumfang"
      name_en="Roadmap length"
    />
    <RoadmapPunchcardRow
      :values="[String(roadmap.year_of_getting_effective)]"
      :values2="roadmap2? [String(roadmap2.year_of_getting_effective)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Jahr des Inkrafttretens"
      name_en="Year of getting effective"
    />
    <RoadmapPunchcardRow
      :values="[String(roadmap.net_zero_year)]"
      :values2="roadmap2? [String(roadmap2.net_zero_year)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Zieljahr Netto-Null"
      name_en="Target net zero year"
    />
    <RoadmapPunchcardRow
      :values="[String(roadmap.ghg_reduction_base_year)]"
      :values2="roadmap2? [String(roadmap2.ghg_reduction_base_year)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Basisjahr für Treibhausgas-Reduktion"
      name_en="GHG reduction base year"
    />
    <RoadmapPunchcardRow
      :values="[roadmap.intermediate_targets_2020_to_2050]"
      :values2="roadmap2? [roadmap2.intermediate_targets_2020_to_2050]:null"
      :roadmap2="roadmap2"
      :type="2"
      name_de="Zwischenziele für Treibhausgas-Reduktionen"
      name_en="Indermediate target for ghg reduction"
      :label_tooltip="$t('roadmaps_punchcard.statistic.tooltip.intermediate_targets_2020_to_2050')"
    />
    <RoadmapPunchcardRow
      :values="[roadmap.references]"
      :values2="roadmap2? [roadmap2.references]:null"
      :roadmap2="roadmap2"
      :type="5"
      name_de="Angegebene Referenzen in der Roadmap"
      name_en="References given in the roadmap"
      :label_tooltip="$t('roadmaps_punchcard.statistic.tooltip.references')"
    />
    <RoadmapPunchcardRow
      :values="[getTerritorialDefinitions(roadmap.territorial_definition)]"
      :values2="roadmap2? [getTerritorialDefinitions(roadmap2.territorial_definition)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Territoriale Gültigkeit in der Roadmap"
      name_en="Territorial validity in the roadmap"
      :label_tooltip="$t('roadmaps_punchcard.statistic.tooltip.territorial_definition')"
    />
    <RoadmapPunchcardRow
      :values="[getLanguages(roadmap.languages)]"
      :values2="roadmap2? [getLanguages(roadmap2.languages)]:null"
      :roadmap2="roadmap2"
      :type="4"
      name_de="Sprachversionen"
      name_en="Languages"
      :label_tooltip="$t('roadmaps_punchcard.statistic.tooltip.languages')"
    />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";

export default {
  name: "RoadmapPunchcardStatistic",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
    languages: null,
    countries: null,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },
  methods: {
    async initialize() {
    },
    // setRoadmap (e) {
    //   this.$emit('setRoadmap', e)
    // },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    getTerritorialDefinitions(counts) {
      let ret = [];
      const countries = this.countries;

      if (counts && countries) {
        counts.forEach(function(country) {
          countries.forEach(function(c) {
            if (country === c.id) {
              ret.push(c.short_name);
            }
          });
        });
      }
      return ret.join(", ");
    },
    getLanguages(langs) {
      let ret = [];
      const languages = this.languages;

      if (langs && languages) {
        langs.forEach(function(language) {
          languages.forEach(function(lang) {
            if (language === lang.id) {
              ret.push(lang.name_en_GB);
            }
          });
        });
      }
      return ret.join(", ");
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
