<template>
  <div class="page about-page">
    <div class="about">
      <frontpage-section :title="$t('menu.about_cluster')">
        <Breadcrumbs class="breadcrumbs"></Breadcrumbs>
        <div>
          <DynText txt="about_netto0"></DynText>
        </div>
      </frontpage-section>
    </div>
    <subtle-footer class="footer"></subtle-footer>
  </div>
</template>

<script>
import FrontpageSection from "@/components/frontpage/Section";
import SubtleFooter from "@/components/SubtleFooter";
import DynText from "@/components/DynText";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "AboutAtlas",
  components: {
    FrontpageSection,
    SubtleFooter,
    DynText,
    Breadcrumbs,
  },
};
</script>

<style lang="scss" scoped>
  .about-page{
    margin-top: 3em;
  }

  .about{
    height: 100vh;
  }

  .breadcrumbs{
    margin-bottom: 1em;
  }
</style>
