<template>
  <div class="panel">
    <div class="description">
      <RoadmapPunchcardLabel
        name_de="Werden Strategien zur Verhaltensänderung erwähnt?"
        name_en="Are behavioural change strategies mentioned?"
      />
    </div>
    <RoadmapPunchcardRow
      :values="[roadmap.behavioural_change]"
      :values2="roadmap2? [roadmap2.behavioural_change]:null"
      :roadmap2="roadmap2"
      :type="5"
      name_de="Verhaltensänderung"
      name_en="Behavioural change"
      :label_tooltip="$t('roadmaps_punchcard.behavioural_change.tooltip.behavioural_change')"
    />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";

export default {
  name: "RoadmapPunchcardSocialParticipation",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },
  methods: {
    async initialize() {
    },
    // setRoadmap (e) {
    //   this.$emit('setRoadmap', e)
    // },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
