export default {
  state: {
    isPortrait: false,
    sectors: null,
    ghgs: null,
    negativeEmissions: null,
  },
  mutations: {
    setIsPortrait(state, value) {
      state.isPortrait = value;
    },
    setSectors(state, value) {
      state.sectors = value;
    },
    setGHGs(state, value) {
      state.ghgs = value;
    },
    setNegativeEmissions(state, value) {
      state.negativeEmissions = value;
    },
    // setAmount (state, value) {
    //   state.amount = value
    // },
    // setLanguages (state, value) {
    //   state.languages = value
    // },
    // setCountries (state, value) {
    //   console.log('set', value)
    //   state.countries = value
    // }
  },
  getters: {
    isPortrait(state) {
      return state.isPortrait;
    },
    getSectors(state) {
      return state.sectors;
    },
    getGHGs(state, value) {
      return state.ghgs;
    },
    getNegativeEmissions(state, value) {
      return state.negativeEmissions;
    },
    // getAmount (state, value) {
    //   return state.amount
    // },
    // getLanguages (state, value) {
    //   return state.languages
    // },
    // getCountries (state, value) {
    //   console.log('get countries', state)
    //   return state.countries
    // }
  },
  actions: {
    setIsPortrait({ commit }, value) {
      commit("setIsPortrait", value);
    },
    setSectors({ commit }, value) {
      commit("setSectors", value);
    },
    setGHGs({ commit }, value) {
      commit("setGHGs", value);
    },
    setNegativeEmissions({ commit }, value) {
      commit("setNegativeEmissions", value);
    },
    // setAmount ({ commit }, value) {
    //   commit('setAmount', value)
    // },
    // setLanguages ({ commit }, value) {
    //   commit('setLanguages', value)
    // },
    // setCountries ({ commit }, value) {
    //   commit('setCountries', value)
    // }
  },
};
