<template>
  <div class="accordion" id="accordionExample">
    <div class="card no-border rounded-0">
      <div class="card-header no-border rounded-0 d-flex cursor-pointer" :class="{'show': showBody, 'turqois': turqois}" @click="showBody = !showBody">
        <div style="">
          <div v-if="level">
            <div v-if="level === 1">
              <img class="information_level" src="../../assets/icons/level_1.png">
            </div>
            <div v-if="level === 2">
              <img class="information_level" src="../../assets/icons/level_2.png">
            </div>
            <div v-if="level === 3">
              <img class="information_level" src="../../assets/icons/level_3.png">
            </div>
          </div>
          <div v-html="title" :aria-label="title"></div>
        </div>
        <div><i v-if="arrow" :class="getIconName"></i></div>
      </div>

      <div id="collapseOne" class="collapse" :class="{'show': showBody}" :aria-expanded="showBody">
        <div v-if="level">
          <small v-if="level === 1" v-html="$t('info_levels.level1')"></small>
          <small v-if="level === 2" v-html="$t('info_levels.level2')"></small>
          <small v-if="level === 3" v-html="$t('info_levels.level3')"></small>
          <hr class="accordion-hr">
        </div>
        <div class="card-body" role="document">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XAccordion",

  components: {
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    turqois: Boolean,
    closed: Boolean,
    level: {
      type: Number,
    },
    arrow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    showBody: true,
  }),

  computed: {
    getIconName() {
      let iconName = "icon-chevron-down";

      if (this.showBody) {
        iconName = "icon-chevron-up";
      }
      return iconName;
    },
  },

  mounted() {
    this.showBody = !this.closed;
  },

  methods: {
  },
};
</script>

<style scoped lang="scss">

  .card-header {
    color: #fff;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    background-color: $accordion-header-bg-color;
    user-select: none;

    > div:first-child {
      flex: 1;
      display: inline-flex;
      align-items: center;
    }
  }

  .accordion-hr {
    border-color: $accordion-header-bg-color;
    margin-top: 0;
  }

  .turqois {
    background-color: #43b0aa;
  }

  .information_level {
    padding-right: 1rem;
  }

</style>
