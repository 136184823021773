<template>
  <span v-if="type === 1">
    <span v-for="value in this.get_ordered_values()" :key="value.id">
      <span v-if="roadmap[field_name].includes(value.id) ">
        <RoadmapPunchcardTFN
          :obj="true"
          :name_de="value.name_de"
          :name_en="value.name_en_GB"
        />
      </span>
      <span v-else>
        <RoadmapPunchcardTFN
          :obj="false"
          :name_de="value.name_de"
          :name_en="value.name_en_GB"
        />
      </span>
    </span>
  </span>
  <span v-else-if="type === 2">
    <span v-for="(value, index) in values" :key="index">
        <RoadmapPunchcardTFN
          :obj="value"
        />
    </span>
  </span>
  <span v-else-if="type === 3">
    <span v-for="(value, index) in values" :key="index">
        <RoadmapPunchcardTFN
          :obj="value !== 4"
        />
    </span>
  </span>
  <span v-else-if="type === 4">
    <span v-if="values[0] !== 'null'">
      {{ values[0] }}
    </span>
    <span v-else>
      -
    </span>
  </span>
  <span v-else-if="type === 5">
    <RoadmapPunchcardAmount
      :value="values[0]"
    />
  </span>
</template>

<script>
import RoadmapPunchcardTFN from "./RoadmapPunchcardTFN";
import RoadmapPunchcardAmount from "./RoadmapPunchcardAmount";

export default {
  name: "RoadmapPunchcardValue",
  components: {
    RoadmapPunchcardTFN,
    RoadmapPunchcardAmount,
  },
  props: {
    roadmap: Object,
    obj: Boolean,
    name_de: String,
    name_en: String,
    values: Array,
    field_name: String,
    type: Number,
  },
  computed: {
  },
  methods: {
    get_ordered_values() {
      let ordered_values = [];
      for (const v in this.values) {
        if (this.roadmap[this.field_name].includes(this.values[v].id)) {
          ordered_values.push(this.values[v]);
        }
      }
      for (const v in this.values) {
        if (!this.roadmap[this.field_name].includes(this.values[v].id)) {
          ordered_values.push(this.values[v]);
        }
      }
      return ordered_values;
    },
  },
};
</script>

<style scoped lang="scss">
  .green {
    color: green;
  }
  .red {
    color:red;
  }
</style>
