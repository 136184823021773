<template>
  <div class="panel">
    <div class="description">
      <RoadmapPunchcardLabel
        name_de="Wurden gesellschaftliche Akteur:innen berücksichtigt?"
        name_en="Have social actors been taken into account?"
      />
    </div>
    <RoadmapPunchcardRow
      :values="[roadmap.actors_involvement_in_development]"
      :values2="roadmap2? [roadmap2.actors_involvement_in_development]:null"
      :roadmap2="roadmap2"
      :type="5"
      name_de="Bei der Entwicklung der Roadmap"
      name_en="For the development of the roadmap?"
      :label_tooltip="$t('roadmaps_punchcard.social_participation.tooltip.actors_involvement_in_development')"
    />
    <RoadmapPunchcardRow
      :values="[roadmap.actors_involvement_in_implementation]"
      :values2="roadmap2? [roadmap2.actors_involvement_in_implementation]:null"
      :roadmap2="roadmap2"
      :type="5"
      name_de="Für die Umsetzung der Roadmap "
      name_en="For the implementation of the roadmap?"
      :label_tooltip="$t('roadmaps_punchcard.social_participation.tooltip.actors_involvement_in_implementation')"
    />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";

export default {
  name: "RoadmapPunchcardSocialParticipation",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },
  methods: {
    async initialize() {
    },
    // setRoadmap (e) {
    //   this.$emit('setRoadmap', e)
    // },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
