<template>
  <div class="page method-map d-flex flex-column">
    <CountryMap :lat="52" :lon="19" :highlightCountries="true" @country="onCountry"></CountryMap>
  </div>
</template>

<script>
import CountryMap from "@/components/map/CountryMap";
export default {
  name: "MapTest",
  components: {
    CountryMap,
  },
  methods: {
    onCountry(shortName) {
      alert(shortName);
    },
  },
};
</script>

<style scoped>
</style>
