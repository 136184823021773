<template>
<transition :name="isPortrait ? '' : 'slide-fade'" appear class="slide-menu">
  <div class="menu d-flex">
    <router-link :to="{ name: 'home'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.start_page") }}</router-link>
    <router-link :to="{ name: 'methodIntro'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.methods") }}</router-link>
    <router-link :to="{ name: 'scenarioIntro'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.scenarios") }}</router-link>
    <router-link :to="{ name: 'roadmaps'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.roadmaps") }}</router-link>
    <router-link :to="{ name: 'partners'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.partner") }}</router-link>
    <router-link :to="{ name: 'glossary'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.glossary") }}</router-link>
    <router-link :to="{ name: 'aboutAtlas'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.about_atlas") }}</router-link>
    <router-link :to="{ name: 'aboutCluster'}" v-on:click.native="emitClose" class="text-center">{{ $t("menu.about_cluster") }}</router-link>
    <a href="https://www.hereon.de/innovation_transfer/communication_media/imprint/index.php.de" target="_blank" rel="nofollow" class="text-center">{{ $t("menu.imprint") }}</a>
    <div class="font-size-switcher text-center" :title="$t('menu.text_size')">
      <span @click="resize('sm')" class="font-sw sw-sm">A</span>
      <span @click="resize('md')" class="font-sw sw-md">A</span>
      <span @click="resize('lg')" class="font-sw sw-lg">A</span>
    </div>
  </div>
</transition>
</template>

<script>
export default {
  name: "Menu",

  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },

  methods: {
    emitClose() {
      this.$emit("toggle-menu");
    },
    resize(size) {
      let elms = document.getElementsByTagName("p");
      for (let i = 0; i < elms.length; i++) {
        switch (size) {
          case "sm":
            elms[i].classList.remove("accessibility-lg");
            elms[i].classList.remove("accessibility-md");
            elms[i].classList.add("accessibility-sm");
            break;
          case "md":
            elms[i].classList.remove("accessibility-lg");
            elms[i].classList.remove("accessibility-sm");
            elms[i].classList.add("accessibility-md");
            break;
          case "lg":
            elms[i].classList.remove("accessibility-sm");
            elms[i].classList.remove("accessibility-md");
            elms[i].classList.add("accessibility-lg");
            break;
        }
      }
      this.emitClose();
    },

  },
};
</script>

<style scoped lang="scss">
  .menu {
    width: rem(250);
    background-color: white;
    border: 1px solid #707070;
    position: absolute;
    top: 2.5em;
    right: 0;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 1000;
  }

  a {
    color: #606060;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    font-weight: 400;

    &:hover {
      color: #005A9A;
    }
  }

  span{
    color: #606060;
    text-decoration: none;
    font-weight: 400;
    margin-left: rem(10);
    cursor: pointer;
  }

  .font-sw{
    font-weight: 800;
  }

  .sw-sm{
      font-size: 12pt;
    }

  .sw-md{
      font-size: 18pt;
    }

  .sw-lg{
      font-size: 24pt;
    }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all .2s ease;
  }

  .slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @media (max-width: rem($break-sm)) {
    .menu {
      width: auto;
      position: fixed;
      top: rem($header-height);
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
</style>
