<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-3 mb-3">
          <ul class="list-unstyled">
            <li><a :href="$t('footer.imprint_link')">{{ $t('footer.imprint')}}</a> </li>
            <li><a :href="$t('footer.privacy_link')">{{ $t('footer.privacy')}}</a> </li>
            <li><a :href="$t('footer.accessibility_link')">{{ $t('footer.accessibility')}}</a> </li>
            <li><a href="mailto:netto-null-2050.atlas@hereon.de">{{$t('footer.contact')}}</a></li>
          </ul>
        </div>

        <div class="col-xs-12 col-sm-6 d-flex justify-content-center align-items-center text-center mb-3">
          <div class="social-icons d-flex justify-content-center">
            <a class="icon-circle" href="https://twitter.com/klimainitiative" target="_blank" aria-label="Twitter"><span><font-awesome-icon :icon="['fab', 'twitter']" size="2x"/></span></a>
            <a class="icon-circle" href="https://www.facebook.com/Klimainitiative-110675250542299/" target="_blank" aria-label="Facebook"><span><font-awesome-icon :icon="['fab', 'facebook-f']" size="2x"/></span></a>
            <a class="icon-circle" href="https://www.youtube.com/channel/UC_f3rIcVzRVmPxm8vmdIISw" target="_blank" aria-label="YouTube"><span><font-awesome-icon :icon="['fab', 'youtube']" size="2x"/></span></a>
            <a class="icon-circle" href="https://de.linkedin.com/company/helmholtz-klima-initiative" target="_blank" aria-label="LinkedIn"><span><font-awesome-icon :icon="['fab', 'linkedin-in']" size="2x"/></span></a>
            <a class="icon-circle" href="https://www.instagram.com/helmholtzklimainitiative/" target="_blank" aria-label="Instagram"><span><font-awesome-icon :icon="['fab', 'instagram']" size="2x"/></span></a>
          </div>
        </div>

        <div class="col-xs-12 col-sm-3 text-right mb-3">
          <img v-if="$i18n.locale === 'en-US'" class="gerics-logo" src="@/assets/GERICS_en.png" title="Gerics Logo"/>
          <img v-else class="gerics-logo" src="@/assets/Logo_GERICS_final_500px_mit_Zusatz.png" title="Gerics Logo"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FrontpageFooter",

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },
};
</script>

<style scoped lang="scss">

footer {
  padding: rem(20) 0 rem(40);

  --x-linear-scale-min-value: 16;
  --x-linear-scale-max-value: 18;
  @include linear-scale((font-size));

  &, a {
    color: $footer-link-color;
  }

  a:hover {
    color: $footer-link-hover-color;
  }
}

.gerics-logo {
  height: rem(110);
}

.social-icons > .icon-circle:not(:last-child) {
  margin-right: rem(10);
}

.icon-circle {
  height: rem(46);
  width: rem(46);
  position: relative;
  background-color: $footer-link-color;
  border-radius: 100%;

  &:hover {
    background-color: $footer-link-hover-color;
  }

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > svg {
      height: rem(22);
      color: #fff;
    }
  }
}

</style>
