<template>
  <div class="breadcrumbs d-flex flex-row py-2">
    <span v-for="(bc, index) in breadcrumbs" v-bind:key="bc.disp">
      <router-link :to="{name: bc.name}" v-if="!bc.last">
        <font-awesome-icon class="home-icon" v-if="index === 0" icon='home' tabindex="0" aria-label="Home" />
        <span v-else class="bc-item" v-html="bc.disp"></span>
      </router-link>
      <span v-else>
        <font-awesome-icon class="home-icon" v-if="index === 0" icon='home'/>
        <span v-else class="bc-item" v-html="bc.disp"></span>
      </span>
      <span v-if="!bc.last" class="bc-divider">></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    currentDisplayName: { type: String, required: false },
    custom: { type: Array, required: false },
    overrideParent: { type: Object, required: false },
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  methods: {
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    render(locale) {
      if (!this.custom) {
        // helper for shortening title
        const truncate = (str, max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;

        let currentParent = this.$route.meta.breadcrumb.parent;

        while (currentParent !== "") {
          let parent = this.$router.resolve({ name: currentParent });

          if (locale === "en-US" || locale === "en") {
            this.breadcrumbs.push({ disp: parent.route.meta.breadcrumb.en, name: parent.route.name });
          } else {
            this.breadcrumbs.push({ disp: parent.route.meta.breadcrumb.de, name: parent.route.name });
          }
          currentParent = parent.route.meta.breadcrumb.parent;
        }

        this.breadcrumbs.reverse();
        if (this.currentDisplayName) {
          this.breadcrumbs.push({ disp: truncate(this.stripHtml(this.currentDisplayName), 59, "..."), name: this.$route.name, last: true });
        } else {
          if (locale === "en-US" || locale === "en") {
            this.breadcrumbs.push({ disp: this.$route.meta.breadcrumb.en, name: this.$route.name, last: true });
          } else {
            this.breadcrumbs.push({ disp: this.$route.meta.breadcrumb.de, name: this.$route.name, last: true });
          }
        }
      } else {
        this.breadcrumbs = this.custom;
      }

      if (this.overrideParent) {
        this.breadcrumbs[this.breadcrumbs.length - 2] = this.overrideParent;
      }
    },
  },
  mounted() {
    this.render(this.$i18n.locale);
  },

  watch: {
    // TODO: Schauen warum das niocht immer geht!
    "$i18n.locale": function(new_locale, old_locale) {
      this.breadcrumbs = [];
      this.render(new_locale);
    },
  },
};
</script>

<style scoped lang="scss">
  .breadcrumbs {
    flex-wrap: wrap;
    a:hover .bc-item {
      color: $brand-1;
      font-weight: 700;
    }
  }

  .bc-divider {
    margin: 0 rem(10) 0 rem(10);
    font-family: "Roboto", "sans-serif";
    font-weight: 400;
    color: $breadcrumb-color;
    font-size: 17px !important;
  }

  .bc-item {
    font-family: "Roboto", "sans-serif" !important;
    color: $breadcrumb-color;
    font-weight: 400 !important;
    font-size: 17px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .home-icon {
    color: $breadcrumb-color;
    font-weight: 300;
    font-size: 17px !important;
  }

  @media screen and (max-width: 479px){
    .bc-item{
      text-wrap: none;
      margin-left: rem(10);
      font-size: rem(12) !important;
    }

    .bc-divider{
      font-size: rem(12) !important;
    }

    .home-icon{
      font-size: rem(12) !important;
    }
  }
</style>
