<template>
  <div class="row">
    <div :class="(slim || roadmap2) ? 'col-sm-4' : 'col-sm-2'">
      <RoadmapPunchcardValue
        :field_name="field_name"
        :values="values"
        :type="type"
        :roadmap="roadmap"
      />
    </div>
    <a :class="(roadmap2) ? 'col-sm-4' : slim ? 'col-sm-8' : 'col-sm-10'" :href="link" target="_blank" v-tooltip="{content: label_tooltip, container: false}">
      <RoadmapPunchcardLabel
        :name_de="name_de"
        :name_en="name_en"
      />
    </a>
    <div v-if="roadmap2" :class="[slim ? 'col-sm-4' : 'col-sm-4', 'text-right']">
      <div v-if="roadmap">
        <RoadmapPunchcardValue
          :field_name="field_name"
          :values="values"
          :type="type"
          :roadmap="roadmap2"
        />
      </div>
      <div v-else>
        <RoadmapPunchcardValue
          :field_name="field_name"
          :values="values2"
          :type="type"
          :roadmap="roadmap2"
        />
      </div>
    </div>
  </div>
</template>

<script>

import RoadmapPunchcardValue from "./RoadmapPunchcardValue";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";

export default {
  name: "RoadmapPunchcardRow",
  components: {
    RoadmapPunchcardValue,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    obj: Boolean,
    name_de: String,
    name_en: String,
    values: Array,
    values2: Array,
    field_name: String,
    type: Number,
    link: null,
    label_tooltip: null,
    slim: Boolean,
  },
};
</script>

<style scoped lang="scss">
  .green {
    color: green;
  }
  .red {
    color:red;
  }
</style>
