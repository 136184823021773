<template>
  <div id="partner">
    <vueper-slides
      class="partner-slider no-shadow"
      :visible-slides="3"
      :gap="3"
      :arrows="!isMobile"
      :dragging-distance="50"
      :bullets="true"
      :breakpoints="{ 1025: { visibleSlides: 2} }"
      :fade="true"
      slide-multiple
      autoplay
      duration="10000"
      transitionSpeed="500"
      fixedHeight="150px"
    >
    <vueper-slide v-for="partner in partners" :key="partner.id" :image="partner.logo" @click.native="goto(partner.id)" :aria-label="partner.name" />
      <template v-slot:bullet="{ active, slideIndex, index }">
        <i class="icon" :class="{'active_circle' : active , 'inactive_circle': !active}"></i>
      </template>
    </vueper-slides>
  </div>
</template>

<script>
import { fetchPartner } from "@/api";
import { VueperSlide, VueperSlides } from "vueperslides";

export default {
  name: "FrontpagePartnerSlider",

  components: {
    VueperSlides,
    VueperSlide,
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  data: () => ({
    partners: null,
    windowWidth: null,
  }),

  created() {
    this.initialize();
  },

  computed: {
    isMobile() {
      if (this.windowWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async initialize() {
      fetchPartner(partners => {
        this.partners = partners;
      });
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    goto(id) {
      this.$router.push("/partners#" + id);
    },
  },
};
</script>

<style scoped lang="scss">

#partner {
  color: #707070;
  margin-bottom: rem(210);
}

.partner-slider {
  > .slide-text {
    text-align: left;
    font-weight: 350;

    > span {
      display:inline-block;
      padding: 0 0.5em;
      margin-bottom: 0.2em;
      background-color: #005a9a;
      opacity: 0.9;
    }
  }

  .vueperslides__arrow svg {
    padding: 0 !important;
  }
}

.active_circle,
.inactive_circle {
  height: rem(15);
  width: rem(15);
  display: inline-block;
  margin: 0 rem(2);
  border-radius: 50%;
  border: rem(2) solid;
}

.active_circle {
  border-color: #707070;
  background-color: #707070;
}

.inactive_circle {
  border-color: #ffffff;
  background-color: #aaaaaa;
}

.vueperslide {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

</style>
