<template>
  <div class="chartlegend">
    <span class="item">
      <span style="backgroundColor: rgba(106,106,106,1.0)" class="box" />
      <div style="margin-right: 0.3em;">
        {{ $t('roadmaps_punchcard.chart_legend.gray_full') }}
      </div>
    </span>
    <span class="item">
      <div style="backgroundColor: rgba(106,106,106,0.75)" class="box" />
      <div style="margin-right: 0.3em;">
        {{ $t('roadmaps_punchcard.chart_legend.gray_half') }}
      </div>
    </span>
    <span class="item">
      <div style="backgroundColor: rgba(53,176,170,1.0)" class="box" />
      <div style="margin-right: 0.3em;">
        {{ $t('roadmaps_punchcard.chart_legend.green_full') }}
      </div>
    </span>
    <span class="item">
      <div style="backgroundColor: rgba(53,176,170,0.5)" class="box" />
      <div style="margin-right: 0.3em;">
        {{ $t('roadmaps_punchcard.chart_legend.green_half') }}
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: "Legend",
};
</script>

<style scoped lang="scss">
  .chartlegend {
    margin-left: 0.1em;
    margin-right: 0.5em;
    font-size: 0.7em;
    display: flex;
    padding-top: rem(5);
    padding-bottom: rem(0) !important;
    margin-bottom: rem(0) !important;
    padding-left: rem(10);
    padding-right: rem(5);
    background-color: rgba(255, 255, 255, .5);

    .item {
      display: flex;
      flex-flow: row nowrap;
      padding: 0.2em;

      .box {
        width: 3em;
        height: 1.3em;
        margin-right: 0.3em;
      }
    }
  }
</style>
