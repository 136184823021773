<template>
  <div id="feedback" @keydown.esc="showModal=false">
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content" ref="dialog">
                <div class="feedback-header">
                  <h2 class="txt text-white">Feedback</h2>
                </div>
                <div class="modal-body" ref="modal-body">
                  <DynText txt="feedback_description"></DynText>
                  <FormulateForm :schema="schema" v-model="feedback"></FormulateForm>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn" @click="showModal=false">{{$t("feedback.abort")}}</button>
                  <button type="button" class="btn" @click="send">{{$t("feedback.send")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <button class="btn btn-secondary btn-circle btn-md btn-feedback" :class="{'shake-horizontal': shake}" v-tooltip="$t('feedback.button')" @click="openMod" :aria-label="$t('feedback.button')">
      <font-awesome-icon icon="comment" size="lg"></font-awesome-icon>
    </button>
  </div>
</template>

<script>
import DynText from "@/components/DynText";
export default {
  name: "Feedback",
  components: { DynText },
  data() {
    return {
      shake: true,
      interval: {},
      showModal: false,
      schema: [],
      feedback: {},
    };
  },
  methods: {
    enableShake() {
      this.interval = setInterval(() => {
        this.shake = !this.shake;
      }, 120000);
    },
    openMod() {
      this.showModal = true;
      this.$nextTick(() => {
        this.$refs["modal-body"].$el.focus();
      });
    },

    send() {
      fetch(this.$apiURL + "/feedback_answers",
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(this.feedback),
        })
        .then(response => response.json())
        .then(data => {
          this.showModal = false;
        });
    },
    load() {
      this.schema = [];
      fetch(this.$apiURL + "/feedback_questions/")
        .then(response => response.json())
        .then(data => {
          let questions = data.results;
          this.schema.push({ type: "hidden", value: this.$route.path, name: "page" });
          this.schema.push({ type: "text", name: "email-hp", class: "email-hp", value: "" });
          for (let question of questions) {
            if (this.$i18n.locale.startsWith("en")) {
              this.schema.push({ component: "h3", children: question.question_en_GB, class: "txt" });
            } else {
              this.schema.push({ component: "h3", children: question.question_de, class: "txt" });
            }
            let select = { name: "select_answer-" + question.id, type: "radio", label: "", options: {} };

            for (let option of question.select_answers) {
              if (this.$i18n.locale.startsWith("en")) {
                select.options[option.id] = option.answer_en_GB;
              } else {
                select.options[option.id] = option.answer_de;
              }
            }

            this.schema.push(select);
            if (question.show_free_text) {
              this.schema.push({ type: "textarea", name: "text-" + question.id, label: this.$i18n.t("feedback.freetext") });
            }
          }
        });
    },
  },
  created() {
    this.enableShake();
    this.load();
  },

  watch: {
    "$i18n.locale": function(newL, oldL) {
      this.load();
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
  #feedback {
    position: fixed;
    right: rem(20);
    bottom: rem(20);
    z-index: 9999;
  }

  .btn-feedback {
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-dialog {
    max-width: 45rem;
    max-height: 40rem;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0 auto !important;
  }

  .modal-header::after{
    content: "";
    display: none;
  }

  .modal-body{
    width: 100%;
    .txt{
      color: rgb(60, 60, 60);
      color: #606060;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
    }
  }

  .formulate-input-label{
    font-family: Roboto;
    font-weight: 300;
    font-feature-settings: "liga", "kern";
    font-kerning: normal;
    font-size: 22px;
    line-height: 33px;
    color: #606060;
  }

  .feedback-header{
    width: 100%;
    height: 50px;
    background-color: #005aa0;
    padding: 12px;
  }

  .email-hp{
    display: none;
  }
</style>
