<template>
    <Story v-if="ready && type === 'Story'" :contrib_id="$route.params.id" :type="contrib_type"></Story>
    <MethodMap v-else-if="ready" :contrib_id="$route.params.id"></MethodMap>
</template>

<script>
import Story from "@/views/Story";
import MethodMap from "@/views/MethodMap";

export default {
  name: "Contribution",
  data() {
    return {
      type: "",
      contrib_type: "",
      ready: false,
    };
  },
  components: {
    Story,
    MethodMap,
  },
  async created() {
    fetch(this.$apiURL + "/contributions/" + this.$route.params.id + "/", { credentials: "include" }).then((response) => {
      return response.json();
    }).then((method) => {
      this.type = method.contribution_type;
      this.contrib_type = method.contribution_affiliation;
      this.ready = true;
    });
  },
};
</script>

<style scoped>

</style>
