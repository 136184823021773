<template>
  <div class="d-flex flex-row countryTooltip">
    <div>
      <img class="countryFlag" :src="flagURL">
    </div>

    <div class="countryName d-flex flex-1 align-items-center pr-5">{{ countryName }}</div>
  </div>
</template>

<script>
import { getName, getFlagURL } from "../tools";

export default {
  name: "CountryTooltip",

  props: {
    feature: Object,
  },

  computed: {
    countryName() {
      return getName(this.feature);
    },

    flagURL() {
      return getFlagURL(this.feature);
    },
  },
};
</script>

<style scoped lang="scss">
  .countryTooltip {
    z-index: 400;
    height: 3em;
    color: #747474;
    letter-spacing: rem(0.5);
    position: absolute;
    background-image: linear-gradient(to right, white, rgba(0,0,0,0.0) 100%);
    border-radius: rem(4);
    text-transform: uppercase;
    font-weight: bold;

    & .countryFlag {
      height: 3em;
      padding-right: 1em;
      border-radius: rem(4);
    }

    & .countryName {
      display: flex;
      font-size: 1.1em;
    }
  }
</style>
