<template>
  <div class="page scenario-intro">
    <frontpage-banner-slider :slides="langSlider"></frontpage-banner-slider>
    <frontpage-section :title="$t('link.methods')">
      <DynText txt="method_text" class="method-dyn-text"></DynText>
      <div v-for="method_type in methodTypes" :key="method_type.id" aria-atomic="true">
        <x-accordion :title="getItemAttr(method_type, 'name', $i18n.locale)" class="mb-4">
          <div class="bullet-list" v-for="scenario in methods" :key="scenario.id">
            <div class="bullet-list-item d-flex" v-if="method_type.name === scenario.method_type && (scenario.contribution_affiliation === 'Method' || scenario.contribution_affiliation === 'Methode')">
              <div class="mr-2"><i class="icon-play" :style="{'color': statusColor(scenario.contribution_status_color)}"></i></div>
              <router-link :title="'Status: ' + scenario.contribution_status" :to="{ name: 'contribution', params: { id: scenario.id } }" v-html="getItemAttr(scenario, 'title', $i18n.locale)"></router-link>
            </div>
          </div>
        </x-accordion>
      </div>
    </frontpage-section>
    <SubtleFooter></SubtleFooter>
  </div>
</template>

<script>
import FrontpageBannerSlider from "@/components/frontpage/BannerSlider";
import FrontpageSection from "@/components/frontpage/Section";
import XAccordion from "@/components/core/Accordion";
import DynText from "@/components/DynText";
import SubtleFooter from "@/components/SubtleFooter";
import { fetchMethodTypes, fetchMethods } from "@/api";
import { getItemAttr, isLoggedIn } from "@/tools";

export default {
  name: "MethodIntro",
  components: {
    FrontpageBannerSlider,
    FrontpageSection,
    XAccordion,
    DynText,
    SubtleFooter,
  },
  props: {
    isLoggedIn: Boolean,
  },
  methods: {
    getItemAttr: getItemAttr,
    statusColor(color) {
      if (this.loggedIn) {
        return color;
      } else {
        return "#005aa0";
      }
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      let panel = event.target.nextSibling;

      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
    loggedIn: false,
    methodTypes: null,
    methods: null,
    baseTexts: null,
    slides: {
      de: [
        {
          id: 1,
          image: require("@/assets/img/slider/iStock-862090574_c_franckreporter.jpg"),
          text: `
            <div class="container">
              <span class="prominent">NETTO-NULL-2050 WEB-ATLAS</span>
              <span>STRATEGIEN UND NEUE WEGE</span>
              <span> ZUR REDUZIERUNG, Vermeidung</span>
              <span>UND ENTNAHME VON CO<sub>2</sub> - EMISSIONEN</span>
              <span>IN DEUTSCHLAND.</span>
            </div>`,
        },
      ],
      en: [
        {
          id: 1,
          image: require("@/assets/img/slider/iStock-862090574_c_franckreporter.jpg"),
          text: `
              <div class="container">
                  <span>Strategies and new Ways</span>
                  <span>For the reduction of</span>
                  <span>CO<sub>2</sub> - Emissions in germany.</span>
              </div>
          `,
        },
      ],
    },
    bullet_image: require("@/assets/bullet.png"),
  }),

  mounted() {
    fetchMethodTypes(methodTypes => {
      this.methodTypes = methodTypes;
    });
    fetchMethods(methods => {
      this.methods = methods;
    });
  },
  async created() {
    this.loggedIn = await isLoggedIn();
  },
  computed: {
    langSlider() {
      if (this.$i18n.locale.startsWith("de")) {
        return this.slides.de;
      } else {
        return this.slides.en;
      }
    },
  },
};
</script>

<style lang="scss">
.bullet-list-item > a p{
  display: inline;
}
.status_border{
  border: 3px solid;
  padding: 5px;
}

.status{
  display: inline;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.method-dyn-text{
  color: $font-color-light !important;
}
</style>
