import Vue from "vue";
import Vuex from "vuex";
import App from "./App";

Vue.use(Vuex);
const DEBUG = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    App,
  },
  strict: DEBUG,
});
