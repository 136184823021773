export const mapStyles = {
  base: {
    weight: 1,
    color: "transparent",
    opacity: 0.2,
    fillOpacity: 0,
  },
  hasData: {
    weight: 1,
    color: "white",
    fillColor: "#f6d031",
    opacity: 0.5,
    fillOpacity: 0.3,
  },
  opponent: {
    weight: 2,
    color: "white",
    fillColor: "rgb(148, 193, 46)",
    opacity: 1,
    fillOpacity: 0.5,
  },
  hovered: {
    weight: 1,
    color: "white",
    opacity: 0.2,
    dashArray: "",
  },
  hoveredWithData: {
    weight: 1,
    color: "white",
    opacity: 1,
    fillOpacity: 0.1,
  },
  selected: {
    weight: 2,
    color: "white",
    fillColor: "rgb(0, 90, 154)",
    dashArray: "",
    opacity: 1,
    fillOpacity: 0.7,
  },
};
