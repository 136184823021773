<template>
  <div class="panel">
    <div class="description">
      <RoadmapPunchcardLabel
        name_de="Werden Budgets und Preise definiert?"
        name_en="Are budgets and prices defined?"
      />
    </div>
    <RoadmapPunchcardRow
      :values="[roadmap.carbon_pricing]"
      :values2="roadmap2? [roadmap2.carbon_pricing]:null"
      :roadmap2="roadmap2"
      :type="2"
      name_de="CO<sub>2</sub>-Bepreisung"
      name_en="CO<sub>2</sub>-pricing"
      :label_tooltip="$t('roadmaps_punchcard.budget_pricing.tooltip.carbon_pricing')"
    />
    <RoadmapPunchcardRow
      :values="[roadmap.ghg_budget_defined]"
      :values2="roadmap2? [roadmap2.ghg_budget_defined]:null"
      :roadmap2="roadmap2"
      :type="2"
      name_de="Definition eines Treibhausgas-Budgets"
      name_en="Has a greenhouse gas budget been defined?"
      :label_tooltip="$t('roadmaps_punchcard.budget_pricing.tooltip.adaption_strategies')"
    />
  </div>
</template>

<script>
import RoadmapPunchcardRow from "./RoadmapPunchcardRow";
import RoadmapPunchcardLabel from "./RoadmapPunchcardLabel";

export default {
  name: "RoadmapPunchcardFurhterOptions",
  components: {
    RoadmapPunchcardRow,
    RoadmapPunchcardLabel,
  },
  props: {
    roadmap: Object,
    roadmap2: Object,
    player1: Object,
    player2: null,
  },
  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
  },
  methods: {
    async initialize() {
    },
    togglePanel(event) {
      /**
       * Toggle active on open accordion, toggle visibility of content
       */
      event.target.classList.toggle("active");
      // var panel = event.target.nextSibling
      let panel = event.target.closest("button").nextSibling;

      if (panel.style.display !== "none") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
  data: () => ({
  }),
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
</style>
